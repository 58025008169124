<template>
  <div
    :class="[
      'phone-inner-box',
      'countdown-box',
      {
        background:
          modeData.logoUrl[0].bgColor == 1 &&
          modeData.logoUrl[0].type != 'leftOneRightOne',
      },
    ]"
    v-if="modeData.logoUrl.length"
  >
    <!-- 左一右二 -->
    <div class="m-card" v-if="modeData.logoUrl[0].type == 'leftOneRightTwo'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="layout" :style="{}">
          <div class="view-height-150" v-if="modeData.logoUrl[0].url.length">
            <img
              class="image-mode"
              :style="{ height: modeData.logoUrl[0].height + 'px' }"
              :src="modeData.logoUrl[0].url[0].url"
            />
            <div
              class="img_text"
              :style="{
                fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: modeData.logoUrl[0].mainTitleBold,
              }"
            >
              {{ modeData.logoUrl[0].mainTitle }}
            </div>
            <div
              class="img_texts"
              :style="{
                fontSize: modeData.logoUrl[0].titleSize + 'px',
                fontWeight: modeData.logoUrl[0].titleBold,
              }"
            >
              {{ modeData.logoUrl[0].title }}
            </div>
          </div>
          <div class="view-height-150" v-else>
            <div
              class="image-mode"
              :style="{ height: modeData.logoUrl[0].height + 'px' }"
            ></div>
            <div
              class="img_text"
              :style="{
                fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: modeData.logoUrl[0].mainTitleBold,
              }"
            >
              {{ modeData.logoUrl[0].mainTitle }}
            </div>
            <div
              class="img_texts"
              :style="{
                fontSize: modeData.logoUrl[0].titleSize + 'px',
                fontWeight: modeData.logoUrl[0].titleBold,
              }"
            >
              {{ modeData.logoUrl[0].title }}
            </div>
          </div>
          <div class="view-height-150">
            <div class="view-height-top" v-if="modeData.logoUrl[1].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height / 2 + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-top" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height / 2 + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-75" v-if="modeData.logoUrl[2].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height / 2 + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-75" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height / 2 + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 一四模式 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'topOneBottomThree'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div v-if="modeData.logoUrl[0].contentType == 0">
          <div
            class="body-modelName"
            v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
          >
            <div
              class="flex_center"
              :style="{
                fontSize: modeData.logoUrl[0].modelSize + 'px',
                lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
                fontWeight: modeData.logoUrl[0].modelBold,
              }"
            >
              <div class="line_left"></div>
              {{ modeData.logoUrl[0].modelName }}
              <div class="line_right"></div>
            </div>
            <div
              :style="{
                fontSize: modeData.logoUrl[0].modelsSize + 'px',
                fontWeight: modeData.logoUrl[0].modelsBold,
                lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
              }"
            >
              {{ modeData.logoUrl[0].modelSubName }}
            </div>
          </div>
          <div class="layout1">
            <div class="layout-view-height-150" v-if="modeData.logoUrl[0].url.length">
              <div
                class="view-height-110"
                v-for="(item, index) in modeData.logoUrl"
                :key="index"
              >
                <img
                  class="image-mode"
                  :style="{ height: modeData.logoUrl[0].height + 'px' }"
                  :src="item.url[0].url"
                  alt
                />
                <div
                  class="img_text"
                  :style="{
                    fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: modeData.logoUrl[0].mainTitleBold,
                  }"
                >
                  {{ item.mainTitle }}
                </div>
                <div
                  class="img_texts"
                  :style="{
                    fontSize: modeData.logoUrl[0].titleSize + 'px',
                    fontWeight: modeData.logoUrl[0].titleBold,
                  }"
                  v-html="formatText(item.title)"
                  i
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="body-modelName"
            v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
          >
            <div
              class="flex_center"
              :style="{
                fontSize: modeData.logoUrl[0].modelSize + 'px',
                lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
                fontWeight: modeData.logoUrl[0].modelBold,
              }"
            >
              <div class="line_left"></div>
              {{ modeData.logoUrl[0].modelName }}
              <div class="line_right"></div>
            </div>
            <div
              :style="{
                fontSize: modeData.logoUrl[0].modelsSize + 'px',
                fontWeight: modeData.logoUrl[0].modelsBold,
                lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
              }"
            >
              {{ modeData.logoUrl[0].modelSubName }}
            </div>
          </div>
          <div class="layout1">
            <div class="layout-view-height-150" v-if="modeData.logoUrl[0].url.length">
              <div
                class="view-height-110"
                @click="handleLink(item)"
                v-for="(item, index) in fileList"
                :key="index"
              >
                <img
                  class="image-mode"
                  :style="{ height: modeData.logoUrl[0].height + 'px' }"
                  :src="item.showPic"
                  alt
                />
                <div
                  class="img_text"
                  :style="{
                    fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: modeData.logoUrl[0].mainTitleBold,
                  }"
                >
                  {{ item.title }}
                </div>
                <div
                  class="img_texts"
                  :style="{
                    fontSize: modeData.logoUrl[0].titleSize + 'px',
                    fontWeight: modeData.logoUrl[0].titleBold,
                  }"
                  v-html="formatText(item.content)"
                  i
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 三三二二 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'topThreeBottomThree'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="layout" :style="{}">
          <!-- <div class="view-height-150">
        <img class="image-mode" :src="modeData.logoUrl[0].img" />
      </div> -->
          <div class="view-height-150-six">
            <div class="view-height-33" v-if="modeData.logoUrl[0].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[0].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[0].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[0].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[1].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[2].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[3].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[3].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[3].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[3].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[4].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[4].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[5].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[5].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[5].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[5].title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 三三模式 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'topThreeCenterThree'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="layout" :style="{}">
          <!-- <div class="view-height-150">
        <img class="image-mode" :src="modeData.logoUrl[0].img" />
      </div> -->
          <div class="view-height-150-six">
            <div class="view-height-33" v-if="modeData.logoUrl[0].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[0].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[0].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[0].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[1].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[2].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[3].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[3].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[3].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[3].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[4].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[4].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[5].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[5].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[5].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[5].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[6].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[6].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[6].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[6].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[6].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[6].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[7].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[7].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[7].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[7].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[7].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[7].title }}
              </div>
            </div>
            <div class="view-height-33" v-if="modeData.logoUrl[8].url.length">
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[8].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[8].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[8].title }}
              </div>
            </div>
            <div class="view-height-33" v-else>
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[8].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[8].title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 单行模式 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'topOneCenterOne'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="layout" :style="{}">
          <div class="view-height-150-one">
            <div class="view-height-100-one" v-if="modeData.logoUrl[0].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[0].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[0].title }}
              </div>
              <!-- <div class="img_text_alls">
              
              </div> -->
            </div>
            <div class="view-height-100-one" v-else>
              <div
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div class="img_text_alls">
                <div
                  class="img_text"
                  :style="{
                    fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: modeData.logoUrl[0].mainTitleBold,
                  }"
                >
                  {{ modeData.logoUrl[0].mainTitle }}
                </div>
                <div
                  class="img_texts"
                  :style="{
                    fontSize: modeData.logoUrl[0].titleSize + 'px',
                    fontWeight: modeData.logoUrl[0].titleBold,
                  }"
                >
                  {{ modeData.logoUrl[0].title }}
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[1].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[2].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[2].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[3].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[3].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[3].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[4].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[4].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[5].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[5].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[5].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[6].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[6].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[6].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[6].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[7].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[7].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[7].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[7].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[8].url.length">
              <img
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[8].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[8].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[8].title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 二四模式 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'topTwoCenterOTwo'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="layout" :style="{}">
          <!-- <div class="view-height-150">
        <img class="image-mode" :src="modeData.logoUrl[0].img" />
      </div> -->
          <div class="view-height-150-sixs">
            <div
              :class="
                modeData.logoUrl[0].mainTitle || modeData.logoUrl[0].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[0].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[0].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[0].title)"
              >
                <!-- {{ modeData.logoUrl[0].title }} -->123123
              </div>
            </div>
            <div
              :class="
                modeData.logoUrl[0].mainTitle || modeData.logoUrl[0].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[0].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[0].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[1].mainTitle || modeData.logoUrl[1].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[1].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[1].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[1].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[1].mainTitle || modeData.logoUrl[1].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
                v-html="formatText(modeData.logoUrl[1].title)"
              ></div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[1].title }}
              </div>
            </div>
            <div
              :class="
                modeData.logoUrl[2].mainTitle || modeData.logoUrl[2].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[2].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[2].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[2].mainTitle || modeData.logoUrl[2].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[2].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[2].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[3].mainTitle || modeData.logoUrl[3].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[3].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[3].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[3].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[3].mainTitle || modeData.logoUrl[3].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[3].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[3].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[4].mainTitle || modeData.logoUrl[4].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[4].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[4].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[4].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[4].mainTitle || modeData.logoUrl[4].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[4].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[4].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[5].mainTitle || modeData.logoUrl[5].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[5].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[5].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[5].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[5].mainTitle || modeData.logoUrl[5].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[5].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[5].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[6].mainTitle || modeData.logoUrl[6].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[6].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[6].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[6].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[6].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[6].mainTitle || modeData.logoUrl[6].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[6].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[6].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[7].mainTitle || modeData.logoUrl[7].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-if="modeData.logoUrl[7].url.length"
            >
              <img
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[7].url[0].url"
                alt
              />
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[7].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[7].title)"
              ></div>
            </div>
            <div
              :class="
                modeData.logoUrl[7].mainTitle || modeData.logoUrl[7].title
                  ? 'view-height-180'
                  : 'view-height-181'
              "
              v-else
            >
              <div
                class="image-mode"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text"
                :style="{
                  fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: modeData.logoUrl[0].mainTitleBold,
                }"
              >
                {{ modeData.logoUrl[7].mainTitle }}
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
                v-html="formatText(modeData.logoUrl[7].title)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 一二模式 -->
    <div class="m-card" v-else-if="modeData.logoUrl[0].type == 'leftOneRightOne'">
      <div class="body" :style="{ width: modeData.logoUrl[0].width + '%' }">
        <div
          class="body-modelName"
          v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
        >
          <div
            class="flex_center"
            :style="{
              fontSize: modeData.logoUrl[0].modelSize + 'px',
              lineHeight: modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelBold,
            }"
          >
            <div class="line_left"></div>
            {{ modeData.logoUrl[0].modelName }}
            <div class="line_right"></div>
          </div>
          <div
            :style="{
              fontSize: modeData.logoUrl[0].modelsSize + 'px',
              lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
              fontWeight: modeData.logoUrl[0].modelsBold,
            }"
          >
            {{ modeData.logoUrl[0].modelSubName }}
          </div>
        </div>
        <div class="leftOneRightOne" :style="{}">
          <div
            class="view-topOneCenterOne"
            :class="{ background: modeData.logoUrl[0].bgColor == 1 }"
          >
            <div class="view-height-100-one" v-if="modeData.logoUrl[0].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[0].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[0].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[0].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[0].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-else>
              <div
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text_all"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>

              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <div
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-one image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                    ></div>
                    <div>
                      {{ modeData.logoUrl[0].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[0].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[1].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[1].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[1].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[1].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[1].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-else>
              <div
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>
              <div
                class="img_text_all"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
              ></div>

              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <div
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-one image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                    ></div>
                    <div>
                      {{ modeData.logoUrl[1].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[1].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view-topOneCenterOne">
            <div class="view-height-100-one" v-if="modeData.logoUrl[2].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[2].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[2].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[2].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[2].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[3].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[3].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[3].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[3].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[3].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view-topOneCenterOne">
            <div class="view-height-100-one" v-if="modeData.logoUrl[4].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[4].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[4].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[4].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[4].title }}
                  </div>
                </div>
              </div>
              <div
                class="img_texts"
                :style="{
                  fontSize: modeData.logoUrl[0].titleSize + 'px',
                  lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                  fontWeight: modeData.logoUrl[0].titleBold,
                }"
              >
                {{ modeData.logoUrl[4].title }}
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[5].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[5].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[5].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[5].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[5].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view-topOneCenterOne">
            <div class="view-height-100-one" v-if="modeData.logoUrl[6].url.length">
              <6 v-if="modeData.logoUrl[0].contentCenterType == 0" class="image-mode-one"
              :style="{ height: modeData.logoUrl[0].height + 'px' }"
              :src="modeData.logoUrl[6].url[0].url" alt />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[6].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[6].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[6].title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="view-height-100-one" v-if="modeData.logoUrl[7].url.length">
              <img
                v-if="modeData.logoUrl[0].contentCenterType == 0"
                class="image-mode-one"
                :style="{ height: modeData.logoUrl[0].height + 'px' }"
                :src="modeData.logoUrl[7].url[0].url"
                alt
              />
              <div :class="modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'">
                <div
                  :class="
                    modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'
                  "
                >
                  <div
                    class="img_text"
                    :style="{
                      fontSize: modeData.logoUrl[0].mainTitleSize + 'px',
                      fontWeight: modeData.logoUrl[0].mainTitleBold,
                    }"
                  >
                    <img
                      v-if="modeData.logoUrl[0].contentCenterType == 1"
                      class="image-mode-center"
                      :style="{
                        height: modeData.logoUrl[0].mainTitleSize + 'px',
                        width: modeData.logoUrl[0].mainTitleSize + 'px',
                      }"
                      :src="modeData.logoUrl[7].url[0].url"
                      alt
                    />
                    <div>
                      {{ modeData.logoUrl[7].mainTitle }}
                    </div>
                  </div>
                  <div
                    class="img_texts"
                    :style="{
                      fontSize: modeData.logoUrl[0].titleSize + 'px',
                      lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                      fontWeight: modeData.logoUrl[0].titleBold,
                    }"
                  >
                    {{ modeData.logoUrl[7].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-foot" @click="handelNews" v-if="modeData.logoUrl[0].contentType != 0">
      <div class="m-foot_text">更多新闻 <i class="el-icon-arrow-right"></i></div>
    </div>

    <operate
      v-if="selected"
      @upComps="upComps"
      @downComps="downComps"
      @delComps="delComps"
    />
  </div>
</template>

<script>
// import tempPhone from "@/mixins/tempPhone";
// import operate from "./operate.vue";

// import { mapGetters } from "vuex";
// import logo from "@/assets/imgs/pic-placeholder.jpg";
import { listPageArticle, treeListArticleCategory } from "@/api/activity";
export default {
  name: "Countdown",
  // filters: {
  //   timeValue(val) {
  //     if (val < 10) return "0" + val;
  //     return val;
  //   },
  // },
  props: {
    id: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    modeData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    // operate,
  },
  // mixins: [tempPhone],
  data() {
    return {
      // logo,
      value: "",
      listType: [],
      query: {
        categoryId: "",
        endDate: "",
        pageNumber: 1,
        pageSize: 4,
        searchValue: "",
        startDate: "",
      },
      fileList: [],
    };
  },

  watch: {},
  created() {
    console.log("countdown", this.modeData);
    this.fatchData();
  },
  methods: {
    handelNews(){
      this.$router.replace({
          name: "news",
        });
    },
    async fatchData() {
      // console.log("相册数据id", this.activityId);
      const res = await listPageArticle(this.query);
      if (res.code === "200") {
        console.log("res", res);
        const result = res.result;
        this.total = Number(result.totalRow);
        var totalPage =
          Math.ceil(this.total / this.query.pageSize) > 0
            ? Math.ceil(this.total / this.query.pageSize)
            : 1;
        if (this.query.pageNumber > totalPage) {
          this.query.pageNumber = totalPage;
          this.fatchData();
          return;
        }
        this.fileList = res.result.list;
      }
    },
    handleLink(item) {
      console.log("item", item);

      window.open(item.linkUrl, "_blank"); //关键在此
    },
    formatText(value) {
      if (value) {
        return value.replace(/\n/g, "<br/>");
      }
    },
    // countDown() {
    //   if (this.Timer) {
    //     clearInterval(this.Timer)
    //     this.Timer = null
    //   }
    //   const startEndTime = this.startEndTime
    //   if (startEndTime.length !== 2) {
    //     this.$message.warning('请设置活动主题相关参数')
    //     return
    //   }
    //   const startTime = this.$moment(startEndTime[0]).valueOf()
    //   const endTime = this.$moment(startEndTime[1]).valueOf()
    //   const currentTime = this.$moment().valueOf()
    //   if (currentTime < startTime) {
    //     this.state = 1
    //     let t = startTime - currentTime
    //     this.Timer = setInterval(() => {
    //       if (t < 1000) {
    //         clearInterval(this.Timer)
    //       }
    //       t -= 1000
    //       this.showtime = t
    //     }, 1000)
    //   } else if (currentTime > startTime && currentTime < endTime) {
    //     this.state = 2
    //     let t = endTime - currentTime
    //     this.Timer = setInterval(() => {
    //       if (t < 1000) {
    //         clearInterval(this.Timer)
    //       }
    //       t -= 1000
    //       this.showtime = t
    //     }, 1000)
    //   } else {
    //     this.state = 3
    //   }
  },
};
</script>

<style lang="scss" scoped>
.image-mode-center {
  border-radius: 50%;
  margin-right: 10px;
}
.view-height-100-one {
  min-height: 150px;
  position: relative;
  .image-mode-center {
  }
  .body-rest-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .body-rest {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.body {
  margin: 8px auto;
}
.image-mode {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  // border-radius: 10px;
}
.layout-view-height-100 {
  .image-mode {
    // width: 80%;
    // height: 100px;
    display: block;
    // border-radius: 10px;
  }
}
.title {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  padding: 10px;
  line-height: 1;
}
.layout {
  padding: 0 0 8px 0;
  margin: 0 0 4px 0;
  // background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftOneRightOne {
  // padding: 0 0 8px 0;
  // margin: 0 0 4px 0;
  // background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.layout1 {
  // padding: 8px;
  margin: 4px 0;
  // background: #fff;

  .layout-view-height-150 {
    // overflow: hidden;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .image-mode {
      height: 100px;
    }
  }
}
.img_text {
  // width: 100%;
  text-align: left;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.img_texts {
  // width: 100%;
  margin-top: 10px;
  text-align: left;
  line-height: 26px;
}
.layout,
.view-height-75,
.view-height-150 {
  // overflow: hidden;
}
.view-height-110 {
  min-height: 100px;
  width: 24%;
  cursor: pointer;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;

  .image-mode {
    width: 100%;
    height: 100%;
    display: block;
    //

    // border-radius: 10px;
  }
}
.m-foot{
  text-align: center;
}
.m-foot_text {
  cursor: pointer;
  width: 100px;
  margin: 20px auto;
  height: 32px;
  background: #ffffff;
  border-radius: 16px;
}
.m-foot_text:hover {
  cursor: pointer;
  width: 100px;
  margin: 20px auto;
  height: 32px;
  background: #ffffff;
  color: #3558ff;
  border-radius: 16px;
}
.view-height-150-six {
  width: 100%;
  // overflow: hidden;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  flex-wrap: wrap;
}
.view-height-150-sixs {
  width: 100%;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-wrap: wrap;
}
.view-height-150-one {
  width: 100%;
  margin: 0 auto;

  .view-height-100-one {
    width: 100%;
    min-height: 150px;
    margin: 10px auto;
    position: relative;
  }
  .img_text_all {
    width: 95%;
    height: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    color: #000 !important;
    background: #f5f5f5;
    opacity: 0.2;
    text-align: center;
  }
  .img_text_alls {
    width: 95%;
    height: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    color: #000 !important;
    text-align: center;
  }

  .image-mode-one {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: block;

    // border-radius: 10px;
    opacity: 0.8;
  }
}
.view-topOneCenterOne {
  width: 100%;
  margin: 0 auto;
  display: flex;
  // align-items: center;
  justify-content: space-between;

  .view-height-100-one {
    width: 100%;
    min-height: 150px;
    margin: 2px auto;
    position: relative;
  }
  .img_text_all {
    width: 95%;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    color: #000 !important;
    background: #f5f5f5;
    opacity: 0.2;
    text-align: center;
  }
  .img_text_alls {
    width: 95%;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    color: #000 !important;
    text-align: center;
  }

  .image-mode-one {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: block;

    // border-radius: 10px;
    opacity: 0.8;
  }
}
.view-width-100 {
  width: 100%;
}

.view-height-75 {
  min-height: 75px;
  margin: 0 5px;
}
.view-height-top {
  min-height: 75px;
  margin: 0 5px;
  // margin-bottom: 40px;
  .image-mode {
    height: 75px;
  }
}
.view-height-100 {
  width: 25%;
  height: 160px;
  margin: 20px 5px;
}
.view-height-33 {
  width: 32%;
  min-height: 160px;
  margin: 10px 5px;
}
.view-height-180 {
  width: 24%;
  // height: 88px;
  margin: 40px 2px;
  margin-top: 0;
  .image-mode {
    width: 100%;
    height: 94%;
    display: block;

    // border-radius: 10px;
  }
}
.view-height-181 {
  width: 24%;
  // height: 88px;
  margin: 2px 2px;
  .image-mode {
    width: 100%;
    height: 94%;
    display: block;

    // border-radius: 10px;
  }
}
.view-height-150 {
  min-height: 190px;
  margin: 10px 5px;
  flex: 1;
}

.view-height-85 {
  height: 85px;
  flex: 1;
}

.model-view {
  display: flex;

  .model-view-menu {
    flex: 1.5;
    min-width: 250px;

    > .model-view-menu-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .model-config {
    flex: 2.5;
  }

  .model-view-content {
    flex: 6;
  }

  .model-item {
    line-height: 1.75;
    font-size: 13px;
    margin: 0 5px 10px 5px;
    border: 1px solid #ededed;
    background: #f6f6f9;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.model-view-content {
  background: #f6f6f9;
  border-radius: 0.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.wap-title {
  line-height: 44px;
  text-align: center;
  height: 44px;
  border-bottom: 1px solid #ededed;
}

.content {
  margin: 20px 0;
  padding: 50px 13px;
  width: 360px;
  // background: url("../../../assets/iPhoneX_model.png") no-repeat;
  height: 780px;
  background-size: 360px;
  overflow: hidden;

  > .component,
  .draggable {
    height: 590px;
    overflow-y: auto;
    background: #ebebeb;
  }

  > .draggable {
    padding-bottom: 100px;
  }
}

.list {
  position: relative;
}

.close {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 99;
}

.model-btn {
  > * {
    margin: 0 4px;
  }
}

.qrCode {
  width: 100px;
  height: 100px;
}

.default-view-content {
  text-align: center;
  font-weight: bold;
  line-height: 2;

  ::v-deep img {
    margin: 0 auto;
  }
}

.model-config {
  overflow-y: auto;
}

.active {
  box-sizing: border-box;
  border: 1px solid #000;
}
</style>

moment(start).isBefore(nowT)
