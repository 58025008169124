<template>
  <div v-if="modeData">
    <div
      :class="[
        'phone-inner-box',
        'lst-box',
        { background: modeData.logoUrl[0].bgColor == 1 },
      ]"
    >
      <div class="m-card">
        <div
          class="m-wrapper store-wrapper"
          :style="{
            width: modeData.logoUrl[0].storeWidth + '%',
          }"
        >
          <div
            class="store-wrapper-modelName"
            v-if="modeData.logoUrl[0].modelName || modeData.logoUrl[0].modelSubName"
          >
            <div
            class="flex_center"
              :style="{
                fontSize: modeData.logoUrl[0].modelSize + 'px',
                fontWeight: modeData.logoUrl[0].modelBold,
              }"
            >
            <div class="line_left"> </div>{{ modeData.logoUrl[0].modelName }}
            <div class="line_right"> </div>
              
            </div>
            <div
              :style="{
                fontSize: modeData.logoUrl[0].modelsSize + 'px',
                fontWeight: modeData.logoUrl[0].modelsBold,
              }"
            >
              {{ modeData.logoUrl[0].modelSubName }}
            </div>
          </div>
          <!-- <div class="m-wrapper-tit">商家信息</div> -->
          <div class="s-info-wrapper">
            <div
              class="s-info"
              :style="
                modeData.logoUrl.length == 8 || modeData.logoUrl.length == 4
                  ? 'width:25%'
                  : 'width:20%'
              "
              v-for="(item, index) in modeData.logoUrl"
            >
            <!--  @click="handleProduct(item)" -->
              <img
                v-if="item.url.length"
                :src="item.url[0].url"
                :style="{
                  borderRadius: modeData.logoUrl[0].borderRadio,
                  width: modeData.logoUrl[0].itemsWidth + '%',
                  height: modeData.logoUrl[0].itemsHeight + 'px',
                }"
                alt=""
              />
              <img
                v-else
                :src="logo"
                cover
                alt=""
                :style="{
                  borderRadius: modeData.logoUrl[0].borderRadio,
                  width: modeData.logoUrl[0].itemsWidth + '%',
                  height: modeData.logoUrl[0].itemsHeight + 'px',
                }"
              />
              <div class="s-info-title" v-if="item.name ||item.subName ">
                <div
                  :class="{ name: true, 'name-place-holder': !item.name }"
                  :style="{
                    fontSize: modeData.logoUrl[0].itemSize + 'px',
                    fontWeight: modeData.logoUrl[0].itemBold,
                  }"
                >
                  {{ item.name }}
                </div>
                <div
                  :class="{ subName: true, 'name-place-holder': !item.subName }"
                  :style="{
                    fontSize: modeData.logoUrl[0].itemsSize + 'px',
                    lineHeight: modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: modeData.logoUrl[0].itemsBold,
                  }"
                >
                  {{ item.subName }}
                </div>
              </div>
              <div class="s-info-cateBody">
                <div v-for="(ite, index) in item.typeName">
                  <div class="s-info-cateName"  @click.stop="handleProduct(ite)">
                    {{ ite.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="bg"></div> -->
      </div>
      <!-- <operate
      v-if="selected"
      @upComps="upComps"
      @downComps="downComps"
      @delComps="delComps"
    /> -->
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import logo from "@/assets/imgs/camera.png";
// import tempPhone from "@/mixins/tempPhone";
// import operate from "./operate.vue";
export default {
  name: "Store",
  components: {
    // operate,
  },
  props: {
    modeData: {
      type: Object,
      default: () => {},
    },
  },
  // mixins: [tempPhone],
  data() {
    return {
      logo,
      value: "",
      listType: [],
    };
  },
  created() {
    // this.listType = this.modeData.logoUrl;
    // console.log('====================2222==',this.listType);
    // this.$bus.$on("valueUpdate", (value) => {
    //   console.log('valeu',value);
    //   this.listType=this.modeData.logoUrl.slice(0,value.length)
    //   console.log('==',this.listType);
    console.log("store", this.modeData);
    // });
  },
  // computed: {
  //   ...mapGetters(["compsList"]),
  //   compsList: {
  //     get() {
  //       return this.$store.state.template.compsList;
  //     },
  //     set(value) {
  //       this.$store.dispatch("updateAllCompData", {
  //         newCompsList: value,
  //       });
  //     },
  //   },
  // },
  mounted() {
    // this.$bus.$on("valueUpdate", (value) => {
    //   if (value) {
    //     this.listType = this.modeData.logoUrl.slice(0, value.length);
    //   } else {
    //     this.listType = this.modeData.logoUrl.slice(0, 4);
    //   }
    // });
  },
  methods: {
    // 商品类目
    handleProduct(item) {
      this.$emit("handleProduct", item);
    },

    getDate(data) {
      let str = "";
      switch (+data) {
        case 1:
          str = "周一";
          break;
        case 2:
          str = "周二";
          break;
        case 3:
          str = "周三";
          break;
        case 4:
          str = "周四";
          break;
        case 5:
          str = "周五";
          break;
        case 6:
          str = "周六";
          break;

        case 7:
          str = "周日";
          break;

        default:
          break;
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.s-info-title {
  width: 85%;
  border-right: 1px solid #d8d8d8;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
}
.s-info-cateBody {
  width: 80%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.s-info-cateName {
  // width: 112px;
  font-family: PingFangSC, PingFang SC;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  padding: 0 14px;
  margin: 4px;
  font-size: 12px;
  color: #3558ff;
  background: #e6eafc;
  border-radius: 18px;
  // opacity: 0.1;
}
</style>
