var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modeData)?_c('div',[_c('div',{class:[
      'phone-inner-box',
      'lst-box',
      { background: _vm.modeData.logoUrl[0].bgColor == 1 },
    ]},[_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"m-wrapper store-wrapper",style:({
          width: _vm.modeData.logoUrl[0].storeWidth + '%',
        })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"store-wrapper-modelName"},[_c('div',{staticClass:"flex_center",style:({
              fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelBold,
            })},[_c('div',{staticClass:"line_left"}),_vm._v(_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
              fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelsBold,
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"s-info-wrapper"},_vm._l((_vm.modeData.logoUrl),function(item,index){return _c('div',{staticClass:"s-info",style:(_vm.modeData.logoUrl.length == 8 || _vm.modeData.logoUrl.length == 4
                ? 'width:25%'
                : 'width:20%')},[(item.url.length)?_c('img',{style:({
                borderRadius: _vm.modeData.logoUrl[0].borderRadio,
                width: _vm.modeData.logoUrl[0].itemsWidth + '%',
                height: _vm.modeData.logoUrl[0].itemsHeight + 'px',
              }),attrs:{"src":item.url[0].url,"alt":""}}):_c('img',{style:({
                borderRadius: _vm.modeData.logoUrl[0].borderRadio,
                width: _vm.modeData.logoUrl[0].itemsWidth + '%',
                height: _vm.modeData.logoUrl[0].itemsHeight + 'px',
              }),attrs:{"src":_vm.logo,"cover":"","alt":""}}),(item.name ||item.subName )?_c('div',{staticClass:"s-info-title"},[_c('div',{class:{ name: true, 'name-place-holder': !item.name },style:({
                  fontSize: _vm.modeData.logoUrl[0].itemSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].itemBold,
                })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:{ subName: true, 'name-place-holder': !item.subName },style:({
                  fontSize: _vm.modeData.logoUrl[0].itemsSize + 'px',
                  lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].itemsBold,
                })},[_vm._v(" "+_vm._s(item.subName)+" ")])]):_vm._e(),_c('div',{staticClass:"s-info-cateBody"},_vm._l((item.typeName),function(ite,index){return _c('div',[_c('div',{staticClass:"s-info-cateName",on:{"click":function($event){$event.stopPropagation();return _vm.handleProduct(ite)}}},[_vm._v(" "+_vm._s(ite.name)+" ")])])}),0)])}),0)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }