<template>
  <div
    ref="vuebar"
    v-bar
    class="vuebar-element vuebar-container-with-bottom"
  > <!-- el1 -->
    <div
      class="vuebar-container"
    >
      <div
        id="MAmap"
        ref="modifyFormName"
      >
        <div class="amap-wrapper">
          <!-- <div class="amap-search-box">
            <el-amap-search-box
              class="search-box"
              :search-option="searchOption"
              :on-search-result="onSearchResult"
            ></el-amap-search-box>
            <div class="location">
              <p :class="{ 'placeholder': !address || !address.length }">
                {{ address && address.length ? `机构地址：${address}` : '请标注机构地址' }}</p>
            </div>
          </div> -->
          <el-amap
            vid="amapDemo"
            :center="center"
            :zoom="15"
            :events="events"
            class="amap-demo"
          >
            <el-amap-marker
              v-if="markers.position.length>0"
              vid="component-marker"
              :position="markers.position"
              :events="markers.events"
              :label="address"
              :icon="iconImgBlue"
            ></el-amap-marker>
            <!-- <el-amap-marker
              v-for="(marker,index) in rmarkers"
              :key="'marker'+index"
              :position="marker"
              :icon="iconImgBlue"
            > </el-amap-marker> -->
            <el-amap-marker
              v-for="(marker, index) in rmarkers"
              :key="index"
              :topWhenClick="true"
              :position="marker.position"
              :events="marker.events"
              :label="marker.label"
              :vid="index"
              class="red"
              :icon="iconImgRed"
            ></el-amap-marker>
          </el-amap>
        </div>
      </div>
      <!-- <div class="hc-dialog-footer">
        <el-button
          plain
          @click="cancelForm(modifyFormName)"
        >取消</el-button>
        <el-button
          type="primary"
          @click="submitForm(modifyFormName)"
        >提交</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
// import dialogForm from '@/mixins/dialogForm'

export default {
  name: 'MMap',
  // mixins: [dialogForm],
  props: {
    slng: {
      type: Number,
      default: 0
    },
    slat: {
      type: Number,
      default: 0
    },
    saddress: {
      type: String,
      default: ''
    }
  },
  data() {
    // eslint-disable-next-line
    const self = this
    return {
      iconImgBlue: require('@/assets/imgs/bd2.png'),
      iconImgRed: require('@/assets/imgs/bd1.png'),
      lng: 118.800051,
      lat: 32.018177,
      rmarkers: [],
      markers: {
        position: []
      },
      searchOption: {
        placeholder: '12313',
        city: '',
        citylimit: false
      },
      center: [0, 0],
      address: null,
      province: '',
      provinceCode: '',
      city: '',
      cityCode: '',
      district: '',
      districtCode: '',
      adcode: '',
      events: {
        click(e) {
          self.lng = e.lnglat.lng
          self.lat = e.lnglat.lat
          // 这里通过高德地图SDK完成；
          // eslint-disable-next-line
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress([self.lng, self.lat], function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                self.address = result.regeocode.formattedAddress
                self.province = result.regeocode.addressComponent.province
                self.city = result.regeocode.addressComponent.city
                self.district = result.regeocode.addressComponent.district
                self.adcode = result.regeocode.addressComponent.adcode
                const locationarr = [self.lng, self.lat]
                self.center = locationarr
                // self.markers = self.markers || { position: [] }
                self.markers.events = {
                  click: self.markerSingeClick
                }
                self.markers.position = locationarr
                self.$nextTick(() => {
                  self.rmarkers = []
                })
              }
            }
          })
        }
      }
    }
  },
  mounted() {
    var that = this
    console.log('slng',that.slng);
    console.log('slat',that.slat);
    console.log('saddress',that.saddress);
    
    this.lng = this.slng || this.lng
    this.lat = this.slat || this.lat
    this.address = this.saddress || this.address
    if (this.address) {
      this.markers.position = [this.lng, this.lat]
      this.markers.events = {
        click: this.markerSingeClick
      }
    }
    this.center = [this.lng, this.lat]

    // eslint-disable-next-line
    var geocoder = new AMap.Geocoder({
      radius: 1000,
      extensions: 'all'
    })
    geocoder.getAddress([this.lng, this.lat], function (status, result) {
      if (status === 'complete' && result.info === 'OK') {
        if (result && result.regeocode) {
          that.province = result.regeocode.addressComponent.province
          that.city = result.regeocode.addressComponent.city
          that.district = result.regeocode.addressComponent.district
          that.adcode = result.regeocode.addressComponent.adcode
        }
      }
    })
  },
  methods: {
    submitForm(formName) {
      if (!this.address || !this.address.length) {
        this.$message({
          message: '请选择机构地址',
          type: 'warning'
        })
        return
      }
      this.ok(formName, {
        lng: this.lng,
        lat: this.lat,
        address: this.address,
        province: this.province,
        city: this.city || this.province,
        cityCode: this.city ? this.cityCode : this.provinceCode,
        district: this.district || this.city,
        districtCode: this.districtCode,
        adcode: this.adcode
      })
    },
    addMarker: function () {
      const lng = 121.5 + Math.round(Math.random() * 1000) / 10000
      const lat = 31.197646 + Math.round(Math.random() * 500) / 10000
      this.markers.position = [lng, lat]
      this.markers.events = {
        click: this.markerSingeClick
      }
    },
    onSearchResult(pois) {
      let latSum = 0
      let lngSum = 0
      if (pois.length > 0) {
        pois.forEach(poi => {
          const { lng, lat } = poi
          lngSum += lng
          latSum += lat
          // this.markers.position = [lng, lat]
          // this.address = poi.address
          // this.lng = lng
          // this.lat = lat
          this.rmarkers.push({
            position: [poi.lng, poi.lat],
            events: {
              click: this.markerClick
            },
            label: {
              content: poi.name,
              offset: [0, -25]
            }
          })
        })
        const centers = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        }
        this.center = [centers.lng, centers.lat]
        // this.position.lng = this.lng
        // this.position.lat = this.lat
      }
    },
    markerSingeClick() {
      this.rmarkers = []
    },
    getCityCode(level, name, spe) {
      const self = this
      const addressInfo = {}
      // eslint-disable-next-line
      const districtSearch = new AMap.DistrictSearch({
        level: level, // 关键字对应的行政区级别
        subdistrict: 1 //  显示下级行政区级数，1表示返回下一级行政区
        // 搜索所有省/直辖市信息
      })
      districtSearch.search(name, function (status, result) {
        try {
          if (status === 'complete') {
            if (level === 'province') {
              self.provinceCode = result.districtList[0].adcode
            } else if (level === 'city') {
              self.cityCode = result.districtList[0].adcode
            } else if (level === 'district') {
              self.districtCode = result.districtList[0].adcode
            } else if (level === 'only') {
              addressInfo.cityCode =
                result.districtList[0].districtList[0].adcode
            }
          }
          return addressInfo
        } catch (error) {
          console.error(error)
        }
      })
    },
    markerClick(res) {
      const self = this
      self.lng = res.target.w.position.lng
      self.lat = res.target.w.position.lat
      // 这里通过高德地图SDK完成；
      // eslint-disable-next-line
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      geocoder.getAddress([self.lng, self.lat], function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            console.log(result, 133)
            self.address = result.regeocode.formattedAddress
            self.province = result.regeocode.addressComponent.province
            self.getCityCode('province', result.regeocode.addressComponent.province)
            self.city = result.regeocode.addressComponent.city
            self.getCityCode('city', result.regeocode.addressComponent.city)
            self.district = result.regeocode.addressComponent.district
            self.getCityCode('district', result.regeocode.addressComponent.district)
            self.adcode = result.regeocode.addressComponent.adcode
            const locationarr = [self.lng, self.lat]
            self.center = locationarr
            // self.markers = self.markers || { position: [], events: {
            //       click: self.markerSingeClick
            // }}
            self.markers.position = locationarr
            self.markers.events = {
              click: self.markerSingeClick
            }
            self.$nextTick(() => {
              self.rmarkers = []
            })
          }
        }
      })
      // if (res.target.Uh.position.O === this.markerView.position[0] && res.target.Uh.position.P === this.markerView.position[1]) {
      //   this.markerView.show = !this.markerView.show
      // } else {
      //   this.markerView.position = [res.target.Uh.position.O, res.target.Uh.position.P]
      //   this.markerView.show = true
      // }
    }
  }
}
</script>
<style lang="scss">
#MAmap {
  position: relative;

  .amap-wrapper {
    width: 100%;
    height: 500px;
  }

  .amapDemo {
  }

  .amap-search-box {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);

    .search-box {
      flex: 0 0 260px;
      width: 260px;
    }

    .location {
      flex: 1;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
      z-index: 10;
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
      box-sizing: border-box;

      p {
        white-space: nowrap; /* 规定文本是否折行 */
        overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
        text-overflow: ellipsis;

        &.placeholder {
          color: #aaa;
        }
      }
    }
  }

  .el-vue-search-box-container {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .search-tips {
    width: 100%;
    border: 0;

    ul {
      li {
        /*border-left: 1px solid #dbdbdb;*/
        /*border-right: 1px solid #dbdbdb;*/
      }
    }
  }
}
.amap-container .amap-icon img {
  max-width: 2rem !important;
  max-height: 2rem !important;
}
</style>

