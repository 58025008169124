<template>
  <div class="content_nav">
    <div id="boxFixed" class="flex_center nav_bar">
      <div class="flex_between nav_bars">
        <img class="logo_img" v-if="shopList" :src="shopList.fullLogoUrl" width="120px" />
        <nav class="content_navs">
          <div class="flex-end content_">
            <a class="relative" v-for="(item, index) in tabsList" :key="index">
              <div class="proudt" @click="handleClick(index)">{{ item }}</div>
            </a>
          </div>
        </nav>
      </div>
    </div>
    <div v-for="(item, index) in menuList" :key="index" :ref="`tab-${index}`">
      <div v-if="item.bizType == 'lcontent' && item.data.picOrVideo == 1">
        <!--  :ref="'tabs-' + index" -->
        <lcontent
          :tabName="tab"
          :modeData="item.data"
          :obj="false"
          :categoryOptions="categoryOptions"
          @handleSearch="handleSearch"
        />
      </div>
      <div v-if="item.bizType == 'cardType'">
        <cardType :modeData="item.data" :obj="false" />
      </div>
      <div v-if="item.bizType == 'store'">
        <Store :modeData="item.data" :obj="false" @handleProduct="handleProduct" />
      </div>
      <div v-if="item.bizType == 'spread'">
        <Spread :modeData="item.data" :obj="false" />
      </div>
      <div v-if="item.bizType == 'lst'">
        <lst :modeData="item.data" :obj="false" />
      </div>
      <div v-if="item.bizType == 'gap'">
        <gap :modeData="item.data" :obj="false" />
      </div>
      <div v-if="item.bizType == 'countdown'">
        <Countdown :modeData="item.data" :obj="false" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import lcontent from "@/components/lcontent.vue";
import cardType from "@/components/cardType.vue";
import Store from "@/components/store.vue";
import Spread from "@/components/spread.vue";
import lst from "@/components/lst.vue";
import gap from "@/components/lst.vue";
import Countdown from "@/components/countdown.vue";
import { getAppletPageByType, listProductCategory } from "@/api/activity";
import { getCompany } from "@/api/customer/outCustomer";
import logo from "@/assets/imgs/logo.png";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
    lcontent,
    cardType,
    Store,
    Spread,
    lst,
    gap,
    Countdown,
  },
  data() {
    return {
      logo,
      tabsList: ["首页", "公司简介", "产品介绍", "客户案例", "联系我们"],
      query: {
        pageSize: 10,
        pageNumber: 1,
        searchValue: "",
        value1: "",
        departmentId: "",
      },
      menuList: [],
      categoryOptions: [],
      shopList: "",
      offsetTop: 0,
      tabs: ['Tab 0', 'Tab 1', 'Tab 2', 'Tab 3', 'Tab 4'] 
    };
  },
  created() {
    this.fatchData();
    this.getShopInfo();
    this.initCategoryData();
  },
  mounted() {
    //监听滚动
    window.addEventListener("scroll", this.initHeight);
    //执行下一步
    this.$nextTick(() => {
      this.offsetTop = document.querySelector("#boxFixed").offsetTop; //吸顶距离顶部的距离
      console.log("this.offsetTop ", this.offsetTop);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight); //在销毁的生命周期取消监听事件,提高性能
  },
  methods: {
    handleClick(e) {
      const index = Number(e) + 1;
      console.log(index);
      // this.$nextTick(() => {
      //   console.log("this.$refs", this.$refs[`tab-${index}`]);
      //     this.$refs[`tab-${index}`].scrollIntoView({
      //       behavior: "smooth",
      //       block: "center",
      //     });
      // });
      // const componentInstance = this.$refs[`tab-${index}`]; // 获取组件实例  
      // console.log('componentInstance',componentInstance.$refs);
      // componentInstance.$refs.scrollIntoView({ behavior: 'smooth' }); 
     // 使用动态生成的 ref 访问组件 DOM 元素  
     const componentRef = this.$refs[`tab-${index}`];  
      if (componentRef && componentRef.length > 0) {  
        // 调用根元素的 scrollIntoView 方法  
        componentRef[0].scrollIntoView({ behavior: 'smooth' });  
      }   
    },
    initHeight() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //计算滚动条距离顶部的距离

      this.isFixed = scrollTop > this.offsetTop ? true : false; //当滚动条大于吸顶距离顶部的距离试,就加上css样式
      console.log("scrollTop", this.isFixed);
    },
    // 获取商家信息
    async getShopInfo() {
      const res = await getCompany();
      this.shopList = res.result;
      console.log("商家信息", this.shopList);
    },
    async fatchData() {
      // console.log("相册数据id", this.activityId);
      const res = await getAppletPageByType({
        bizType: "index",
      });
      if (res.code === "200") {
        console.log("res", res);

        const result = JSON.parse(res.result.json);
        const newArr = [];
        result.forEach((ele) => {
          var arr = ele.data;
          var test = JSON.parse(arr); //data为数组，name为修改前，new_name为修改后
          newArr.push({
            bizType: ele.bizType,
            data: test,
            sortNum: ele.sortNum,
          });
        });

        this.menuList = newArr;
        console.log("this.menuList", this.menuList);
      }
    },
    initCategoryData() {
      // 查询类目接口
      listProductCategory()
        .then((res) => {
          console.log("查询类目", res);
          if (res.code === "200") {
            if (res.result.list && res.result.list.length > 0) {
              var arr = res.result.list;
              this.categoryOptions = arr;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 搜索
    handleSearch(data) {
      console.log("data", data);
      if (!data.searchValue && !data.category) {
        this.$message.warning("请输入搜索内容");
      } else {
        this.$router.push({
          name: "product",
          params: { data },
        });
      }
    },
    // 商品类目
    handleProduct(item) {
      console.log("item", item);

      this.$router.push({
        name: "product",
        params: { category: item.typeValue },
      });
    },
  },
};
</script>

<style lang="scss">
.rounded {
  cursor: pointer;
}
.tooltip {
  display: none;
  // right: 30px;
  // top: 30px;
  position: absolute;
  // right: 30px;
  // top: 80px;
  background-color: #f7fbff;
  color: #000;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
  .tooltip_name {
    margin: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .tooltip_name:hover {
    margin: 10px;
    font-size: 14px;
    color: #3558ff;
    cursor: pointer;
  }
}
.tooltips {
  width: 200px;
  text-align: left;
}
.color {
  font-size: 16px;
  color: #3558ff;
}
.color-weight {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.hover-target:hover .tooltip {
  display: block;
  display: flex;
  position: absolute;
  background-color: #f7fbff;
  color: #000;
  // right: 30px;
  // top: 80px;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
}
.content_navs {
  width: 70%;
  // margin: 0;
  .content_ {
    // width: 50%;
    // margin-left: 200px;
  }
}
</style>
