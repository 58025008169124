var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modeData.logoUrl.length)?_c('div',{class:[
    'phone-inner-box',
    'countdown-box',
    {
      background:
        _vm.modeData.logoUrl[0].bgColor == 1 &&
        _vm.modeData.logoUrl[0].type != 'leftOneRightOne',
    },
  ]},[(_vm.modeData.logoUrl[0].type == 'leftOneRightTwo')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout",style:({})},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"view-height-150"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url}}),_c('div',{staticClass:"img_text",style:({
              fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
              fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].titleBold,
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]):_c('div',{staticClass:"view-height-150"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
              fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
              fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].titleBold,
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]),_c('div',{staticClass:"view-height-150"},[(_vm.modeData.logoUrl[1].url.length)?_c('div',{staticClass:"view-height-top"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height / 2 + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]):_c('div',{staticClass:"view-height-top"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height / 2 + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]),(_vm.modeData.logoUrl[2].url.length)?_c('div',{staticClass:"view-height-75"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height / 2 + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]):_c('div',{staticClass:"view-height-75"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height / 2 + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])])])])])]):(_vm.modeData.logoUrl[0].type == 'topOneBottomThree')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].contentType == 0)?_c('div',[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
              fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
              lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelBold,
            })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
              fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelsBold,
              lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout1"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"layout-view-height-150"},_vm._l((_vm.modeData.logoUrl),function(item,index){return _c('div',{key:index,staticClass:"view-height-110"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":item.url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                  fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                })},[_vm._v(" "+_vm._s(item.mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                  fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].titleBold,
                }),attrs:{"i":""},domProps:{"innerHTML":_vm._s(_vm.formatText(item.title))}})])}),0):_vm._e()])]):_c('div',[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
              fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
              lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelBold,
            })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
              fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
              fontWeight: _vm.modeData.logoUrl[0].modelsBold,
              lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
            })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout1"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"layout-view-height-150"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"view-height-110",on:{"click":function($event){return _vm.handleLink(item)}}},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":item.showPic,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                  fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"img_texts",style:({
                  fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].titleBold,
                }),attrs:{"i":""},domProps:{"innerHTML":_vm._s(_vm.formatText(item.content))}})])}),0):_vm._e()])])])]):(_vm.modeData.logoUrl[0].type == 'topThreeBottomThree')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout",style:({})},[_c('div',{staticClass:"view-height-150-six"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]),(_vm.modeData.logoUrl[1].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]),(_vm.modeData.logoUrl[2].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]),(_vm.modeData.logoUrl[3].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])]),(_vm.modeData.logoUrl[4].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]),(_vm.modeData.logoUrl[5].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])])])])])]):(_vm.modeData.logoUrl[0].type == 'topThreeCenterThree')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout",style:({})},[_c('div',{staticClass:"view-height-150-six"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]),(_vm.modeData.logoUrl[1].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]),(_vm.modeData.logoUrl[2].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]),(_vm.modeData.logoUrl[3].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])]),(_vm.modeData.logoUrl[4].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]),(_vm.modeData.logoUrl[5].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])]),(_vm.modeData.logoUrl[6].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[6].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].title)+" ")])]),(_vm.modeData.logoUrl[7].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[7].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].title)+" ")])]),(_vm.modeData.logoUrl[8].url.length)?_c('div',{staticClass:"view-height-33"},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[8].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].title)+" ")])]):_c('div',{staticClass:"view-height-33"},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].title)+" ")])])])])])]):(_vm.modeData.logoUrl[0].type == 'topOneCenterOne')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout",style:({})},[_c('div',{staticClass:"view-height-150-one"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])]):_c('div',{staticClass:"view-height-100-one"},[_c('div',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text_alls"},[_c('div',{staticClass:"img_text",style:({
                  fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                  fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                  fontWeight: _vm.modeData.logoUrl[0].titleBold,
                })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])])]),(_vm.modeData.logoUrl[1].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[2].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[3].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[4].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[5].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[6].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[6].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[7].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[7].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[8].url.length)?_c('div',{staticClass:"view-height-100-one"},[_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[8].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[8].title)+" ")])]):_vm._e()])])])]):(_vm.modeData.logoUrl[0].type == 'topTwoCenterOTwo')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"layout",style:({})},[_c('div',{staticClass:"view-height-150-sixs"},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{class:_vm.modeData.logoUrl[0].mainTitle || _vm.modeData.logoUrl[0].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[0].title))}},[_vm._v("123123 ")])]):_c('div',{class:_vm.modeData.logoUrl[0].mainTitle || _vm.modeData.logoUrl[0].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[0].title))}})]),(_vm.modeData.logoUrl[1].url.length)?_c('div',{class:_vm.modeData.logoUrl[1].mainTitle || _vm.modeData.logoUrl[1].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[1].title))}})]):_c('div',{class:_vm.modeData.logoUrl[1].mainTitle || _vm.modeData.logoUrl[1].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[1].title))}}),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])]),(_vm.modeData.logoUrl[2].url.length)?_c('div',{class:_vm.modeData.logoUrl[2].mainTitle || _vm.modeData.logoUrl[2].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[2].title))}})]):_c('div',{class:_vm.modeData.logoUrl[2].mainTitle || _vm.modeData.logoUrl[2].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[2].title))}})]),(_vm.modeData.logoUrl[3].url.length)?_c('div',{class:_vm.modeData.logoUrl[3].mainTitle || _vm.modeData.logoUrl[3].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[3].title))}})]):_c('div',{class:_vm.modeData.logoUrl[3].mainTitle || _vm.modeData.logoUrl[3].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[3].title))}})]),(_vm.modeData.logoUrl[4].url.length)?_c('div',{class:_vm.modeData.logoUrl[4].mainTitle || _vm.modeData.logoUrl[4].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[4].title))}})]):_c('div',{class:_vm.modeData.logoUrl[4].mainTitle || _vm.modeData.logoUrl[4].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[4].title))}})]),(_vm.modeData.logoUrl[5].url.length)?_c('div',{class:_vm.modeData.logoUrl[5].mainTitle || _vm.modeData.logoUrl[5].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[5].title))}})]):_c('div',{class:_vm.modeData.logoUrl[5].mainTitle || _vm.modeData.logoUrl[5].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[5].title))}})]),(_vm.modeData.logoUrl[6].url.length)?_c('div',{class:_vm.modeData.logoUrl[6].mainTitle || _vm.modeData.logoUrl[6].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[6].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[6].title))}})]):_c('div',{class:_vm.modeData.logoUrl[6].mainTitle || _vm.modeData.logoUrl[6].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[6].title))}})]),(_vm.modeData.logoUrl[7].url.length)?_c('div',{class:_vm.modeData.logoUrl[7].mainTitle || _vm.modeData.logoUrl[7].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('img',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[7].url[0].url,"alt":""}}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[7].title))}})]):_c('div',{class:_vm.modeData.logoUrl[7].mainTitle || _vm.modeData.logoUrl[7].title
                ? 'view-height-180'
                : 'view-height-181'},[_c('div',{staticClass:"image-mode",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text",style:({
                fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              }),domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.modeData.logoUrl[7].title))}})])])])])]):(_vm.modeData.logoUrl[0].type == 'leftOneRightOne')?_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"body",style:({ width: _vm.modeData.logoUrl[0].width + '%' })},[(_vm.modeData.logoUrl[0].modelName || _vm.modeData.logoUrl[0].modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.logoUrl[0].modelSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].modelLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.logoUrl[0].modelsSize + 'px',
            lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
            fontWeight: _vm.modeData.logoUrl[0].modelsBold,
          })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"leftOneRightOne",style:({})},[_c('div',{staticClass:"view-topOneCenterOne",class:{ background: _vm.modeData.logoUrl[0].bgColor == 1 }},[(_vm.modeData.logoUrl[0].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[0].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])])])]):_c('div',{staticClass:"view-height-100-one"},[_c('div',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text_all",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('div',{staticClass:"image-mode-one image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    })}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[0].title)+" ")])])])]),(_vm.modeData.logoUrl[1].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[1].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])])])]):_c('div',{staticClass:"view-height-100-one"},[_c('div',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{staticClass:"img_text_all",style:({ height: _vm.modeData.logoUrl[0].height + 'px' })}),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('div',{staticClass:"image-mode-one image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    })}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[1].title)+" ")])])])])]),_c('div',{staticClass:"view-topOneCenterOne"},[(_vm.modeData.logoUrl[2].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[2].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[2].title)+" ")])])])]):_vm._e(),(_vm.modeData.logoUrl[3].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[3].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[3].title)+" ")])])])]):_vm._e()]),_c('div',{staticClass:"view-topOneCenterOne"},[(_vm.modeData.logoUrl[4].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[4].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])])]),_c('div',{staticClass:"img_texts",style:({
                fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                fontWeight: _vm.modeData.logoUrl[0].titleBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[4].title)+" ")])]):_vm._e(),(_vm.modeData.logoUrl[5].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[5].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[5].title)+" ")])])])]):_vm._e()]),_c('div',{staticClass:"view-topOneCenterOne"},[(_vm.modeData.logoUrl[6].url.length)?_c('div',{staticClass:"view-height-100-one"},[_vm._v(" <6 v-if=\"modeData.logoUrl[0].contentCenterType == 0\" class=\"image-mode-one\" :style=\"{ height: modeData.logoUrl[0].height + 'px' }\" :src=\"modeData.logoUrl[6].url[0].url\" alt /> "),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[6].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[6].title)+" ")])])])]):_vm._e(),(_vm.modeData.logoUrl[7].url.length)?_c('div',{staticClass:"view-height-100-one"},[(_vm.modeData.logoUrl[0].contentCenterType == 0)?_c('img',{staticClass:"image-mode-one",style:({ height: _vm.modeData.logoUrl[0].height + 'px' }),attrs:{"src":_vm.modeData.logoUrl[7].url[0].url,"alt":""}}):_vm._e(),_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest'},[_c('div',{class:_vm.modeData.logoUrl[0].contentCenterType == 0 ? '' : 'body-rest-flex'},[_c('div',{staticClass:"img_text",style:({
                    fontSize: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].mainTitleBold,
                  })},[(_vm.modeData.logoUrl[0].contentCenterType == 1)?_c('img',{staticClass:"image-mode-center",style:({
                      height: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                      width: _vm.modeData.logoUrl[0].mainTitleSize + 'px',
                    }),attrs:{"src":_vm.modeData.logoUrl[7].url[0].url,"alt":""}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].mainTitle)+" ")])]),_c('div',{staticClass:"img_texts",style:({
                    fontSize: _vm.modeData.logoUrl[0].titleSize + 'px',
                    lineHeight: _vm.modeData.logoUrl[0].titileLineHeight + 'px',
                    fontWeight: _vm.modeData.logoUrl[0].titleBold,
                  })},[_vm._v(" "+_vm._s(_vm.modeData.logoUrl[7].title)+" ")])])])]):_vm._e()])])])]):_vm._e(),(_vm.modeData.logoUrl[0].contentType != 0)?_c('div',{staticClass:"m-foot",on:{"click":_vm.handelNews}},[_vm._m(0)]):_vm._e(),(_vm.selected)?_c('operate',{on:{"upComps":_vm.upComps,"downComps":_vm.downComps,"delComps":_vm.delComps}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-foot_text"},[_vm._v("更多新闻 "),_c('i',{staticClass:"el-icon-arrow-right"})])
}]

export { render, staticRenderFns }