<template>
  <div :class="['phone-inner-box', 'lst-box']">
    <div class="m-card">
      <div
        class="m-wrapper lst-wrapper"
        :style="{
          width: modeData.modelWidth + '%',
        }"
      >
        <div
          class="body-modelName"
          v-if="modeData.modelName || modeData.modelSubName"
        >
          <div
          class="flex_center"
            :style="{
              fontSize: modeData.modelSize + 'px',
              fontWeight: modeData.modelBold,
            }"
          >
          <div class="line_left"> </div>{{ modeData.modelName }}
            <div class="line_right"> </div>
            
          </div>
          <div
            :style="{
              fontSize: modeData.modelsSize + 'px',
              fontWeight: modeData.modelsBold,
            }"
          >
            {{ modeData.modelSubName }}
          </div>
        </div>

        <div class="m-wrapper-tit" :style="{}">
          <!-- <div class="content_introu" v-html="formatText(company.introduce)"></div> -->
          <div class="content_introu" style="white-space: pre-line;text-indent: 24px;" v-html="company.introduce.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"></div>
        </div>
      </div>
    </div>
    <!-- <operate
      v-if="selected"
      @upComps="upComps"
      @downComps="downComps"
      @delComps="delComps"
    /> -->
  </div>
</template>

<script>
// import tempPhone from "@/mixins/tempPhone";
// import operate from "./operate.vue";
// import { mapGetters } from "vuex";
// import { listPageProduct } from "@/api/product";
import { getCompany } from "@/api/customer/outCustomer";
// import { listProductCategory } from "@/api/product/category";
export default {
  name: "Spread",
  components: {
    // operate,
  },
  props: {
    modeData: {
      type: Object,
      default: () => {},
    },
  },
  // mixins: [tempPhone],
  data() {
    return {
      picList: [],
      options: [],
      company: {},
    };
  },
  mounted() {
    console.log("cardType", this.modeData);

    this.initGradeList();
  },
  methods: {
    formatText(value) {
      if (value) {
        return value.replace(/\n/g, "<br/>");
      }
    },
    async initGradeList() {
      const res = await getCompany();
      this.company = res.result;
    },
  },
};
</script>

<style lang="scss" scoped>
.m-wrapper-tit {
  width: 100%;
  // display: flex;
  display: -webkit-box;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  .m-item {
    width: 50%;
    text-align: center;
    margin: 0 10px;
  }
}
::-webkit-scrollbar {
  display: none;
}
.name {
  text-align: center;
  font-size: 12px;
}
.content_introu {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 32px;
  text-align: left;
  font-style: normal;
}
.btn {
  color: #82c8af;
  height: 38px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 80%;
  margin: 10px auto;
  font-size: 15px;
}
</style>
