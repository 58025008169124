<template>
  <div v-if="modeData" ref="innerElement">
    <div v-if="modeData.picOrVideo === 2 && modeData.value" class="works">
      <!-- :style="{ color: compsList[0].bgColor == '#ffffff' ? '#000000' : '#ffffff' }" -->
      动态作品
    </div>
    <div :class="['phone-inner-box', 'lcontent-box']">
      <div
        class="lcontent-box-modelName"
        v-if="modeData.modelName || modeData.modelSubName"
      >
        <div
        class="flex_center"
          :style="{
            fontSize: modeData.modelSize + 'px',
            lineHeight: modeData.modelLineHeight + 'px',
            fontWeight: modeData.modelBold,
          }"
        >
          <div class="line_left"> </div> {{ modeData.modelName }}
          <div class="line_right"> </div>
        </div>
        <div
          :style="{
            fontSize: modeData.modelsSize + 'px',
            fontWeight: modeData.modelsBold,
          }"
        >
          {{ modeData.modelSubName }}
        </div>
      </div>
      <div v-if="modeData.picList.length" class="pv-ls-box">
        <div
          ref="carousel"
          :style="{
            width: modeData.picWidth + '%',
            margin: '0 auto',
          }"
          class="lcontent_body"
          v-if="modeData.picOrVideo === 1"
        >
          <el-carousel
            :height="modeData.picHeight + 'px'"
            v-if="modeData.picOrVideo === 1"
            autoplay
            :autoplay-speed="3000"
          >
            <!-- @change="changeSwiper" -->
            <el-carousel-item
              v-for="(item, index) in modeData.picList.filter((r) => {
                return r.url.length != 0;
              })"
              :key="index"
            >
              <div
                :class="isAnm ? 'thisbucuo move-img' : 'thisbucuo'"
                v-if="item.url.length"
              >
                <img
                  class="image-mode"
                  :style="{
                    height: modeData.picHeight + 'px',
                  }"
                  :src="item.url[0].url"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
          <div
            class="lcontent_input"
            :style="{
              top: modeData.picHeight / 2 - 42 + 'px',
            }"
          >
            <div
              class="lcontent_inputs"
              :style="{
                textAlign:
                  modeData.contentPos == 2
                    ? 'left'
                    : modeData.contentPos == 3
                    ? 'right'
                    : 'center',
              }"
            >
              <div
                :style="{
                  fontSize: modeData.modelContentSize + 'px',
                  fontWeight: modeData.modelContentBold,
                }"
              >
                {{ modeData.modelContent }}
              </div>
              <div
                :style="{
                  fontSize: modeData.modelSubContentSize + 'px',
                  fontWeight: modeData.modelSubContentBold,
                  margin: '30px 0 0 0',
                }"
              >
                {{ modeData.modelSubContent }}
              </div>
            </div>
            <el-row
              align="center"
              style="margin-top: 20px"
              type="flex"
              justify="center"
              v-if="modeData.searchType == 0"
            >
              <el-col :span="10">
                <el-input
                  placeholder="Search by target, catalog number, uniprot ID or keyword"
                  v-model="query.searchValue"
                  @keyup.enter.native="handleSearch"
                  class="input-with-select"
                >
                  <el-select v-model="query.category" slot="prepend" placeholder="请选择">
                    <el-option
                      :label="item.name"
                      :value="item.categoryId"
                      v-for="(item, index) in categoryOptions"
                      :key="item.categoryId"
                    ></el-option>
                  </el-select>
                  <el-button slot="append" type="primary" @click="handleSearch"
                    >搜索</el-button
                  >
                </el-input>
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          v-if="modeData.picOrVideo === 2"
          :style="{
            height: modeData.videoHeight + 'px',
            borderRadius: modeData.videoRadius + 'px',
          }"
          class="pv-video-ls"
        >
          <template v-if="modeData.videoList.length">
            <img
              v-for="(item, index) in modeData.videoList"
              :key="index"
              :src="item.poster"
              width="100%"
            />
            <!-- <video :src="modeData.videoList[0].url" id="upvideo" width="100px" style="max-height: 11vh; outline:none"
              controls="controls"></video> -->
            <i class="el-icon-video-play"></i>
          </template>
          <template v-else>
            <img
              :src="modeData.picList.length ? modeData.picList[0].url : defaultPoster"
              width="100%"
            />
            <i class="el-icon-video-play"></i>
          </template>
        </div>
      </div>
      <div v-else class="pv-ls-box">
        <img :src="placeHolderImgUrl" alt="" width="100%" />
      </div>
      <!-- <operate
        v-if="selected"
        @upComps="upComps"
        @downComps="downComps"
        @delComps="delComps"
      /> -->
    </div>
    <div
      v-if="modeData.picOrVideo === 2"
      :style="{ color: compsList[0].bgColor == '#ffffff' ? '#000000' : '#ffffff' }"
      class="input_text"
    >
      {{ modeData.input }}
    </div>
  </div>
</template>

<script>
// import tempPhone from "@/mixins/tempPhone";
// import operate from "./operate.vue";
// import { mapGetters } from "vuex";
export default {
  name: "Lcontent",
  components: {
    // operate,
  },
  // mixins: [tempPhone],
  props: {
    modeData: {
      type: Object,
      default: () => {},
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    isAnm: {
      type: Boolean,
      default: true,
    },
    tabName: {  
      type: String,  
      required: true,  
    },  
  },
  data() {
    return {
      defaultPoster: "https://via.placeholder.com/200/FAF1EC/F4B99D?text=poster",
      newHeight: "500px",
      screenWidth: 0,
      select: "",
      searchValue: "",
      newArr: [],
      query: {
        searchValue: "",
        category: "",
      },
    };
  },
  // watch: {
  //   modeData: {
  //     handler(oldValue, newValue) {
  //       console.log("oldValue", oldValue, "newValue", newValue);
  //     },
  //   },
  // },
  // computed: {
  //   ...mapGetters(["compsList", "moduleObj"]),
  //   compsList: {
  //     get() {
  //       console.log("get");
  //       return this.$store.state.template.compsList;
  //     },
  //     set(value) {
  //       console.log("set");
  //       this.$store.dispatch("updateAllCompData", {
  //         newCompsList: value,
  //       });
  //     },
  //   },
  //   //这里设置banner 是在900像素下高度为200px，我的banner图格式为1920X900像素
  //   // newHeight: {
  //   //   get() {
  //   //     // let _w = document.documentElement.clientWidth || document.body.clientWidth;
  //   //     // console.log("7897987897987987", _w);
  //   //     // return (_w * 200) / 450 + "px";
  //   //   },
  //   //   set() {},
  //   // },
  // },

  created() {
    console.log("icontent组件1", this.modeData);
    // if(this.modeData.picOrVideo==1){
    //   this.modeData.picList.map(res=>{
    //     console.log('----',res.url);
    //     res.url=res.url.map(ret=>{
    //         return ret.url
    //       })
    //   })
    // }
    // if (this.modeData.picList.length) {
    // if (this.modeData.picOrVideo === 1) {
    //   // 创建实例对象
    //   var img = new Image();
    //   // 图片地址;
    //   img.src = this.modeData.picList[0].url;
    //   console.log("src====", img.src);
    //   console.log("src1====", img);
    //   // 打印;
    //   console.log("width:" + img.width + ",height:" + img.height);
    //   this.newHeight = img.height / 2 + "px";
    // }
    // }
  },
  mounted() {
    console.log("icontent组件", this.modeData);
    // this.$bus.$on("valueUpdate", (value) => {
    //   if (value) {
    //     this.newArr = this.modeData;
    //     console.log("newArr", value);
    //   } else {
    //     console.log("value", value);
    //   }
    // });
    // 首次加载时,初始化高度
    // this.screenWidth = window.innerWidth
    // this.newHeight = 600 / 1550 * this.screenWidth
    // // 窗口大小发生改变
    // window.onresize = () => {
    //   this.screenWidth = window.innerWidth
    //   this.newHeight = 600 / 1550 * this.screenWidth
    // }
  },

  methods: {
    // 搜索
    handleSearch() {
      this.$emit("handleSearch", this.query);
    },
    // imgLoad() {
    //   this.changeSwiper(0);
    // },
    // changeSwiper(e) {
    //   // 创建实例对象
    //   var img = new Image();
    //   var newArr = this.modeData.picList.filter((r) => {
    //     return r.url.length != 0;
    //   });
    //   // console.log(newArr[e].url, "img");
    //   if (newArr[e].url.length) {
    //     // 图片地址;
    //     img.src = newArr[e].url[0].url;
    //     // console.log(img.src, "img.src");
    //     // console.log("width:" + img.width + ",height:" + img.height);
    //     // 打印;
    //     var bpr = img.width / 375;
    //     this.newHeight = img.height / bpr + "px";
    //     // console.log(this.newHeight, "this.newHeight");
    //   }
    //   //  setTimeout(()=>{
    //   // 获取div的ref=“carousel”的高度
    //   //   let carousel_height = window.getComputedStyle(this.$refs.carousel).height;
    //   // console.log('window.getComputedStyle(this.$refs.carousel).',carousel_height);
    //   // // 赋值给 el-carousel中height动态绑定的carouselHeight
    //   // this.newHeight = carousel_height;
    //   // console.log("carousel_height", carousel_height);
    //   //  },1000)
    // },
  },
};
</script>

<style lang="scss" scoped>
.input_text {
  width: 340px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.move-img {
  animation-name: scaleDraw;
  /*关键帧名称*/
  animation-timing-function: ease-in-out;
  /*动画的速度曲线*/
  animation-iteration-count: 1;
  /*动画播放的次数*/
  animation-duration: 3s;
  /*动画所花费的时间*/
  overflow: hidden;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1.5);
    /*开始为原始大小*/
  }

  100% {
    transform: scale(1);
  }
}

::v-deep .el-input-group {
  height: 62px;
  border-radius: 31px;
  background: rgba(255, 255, 255, 0.5);
  // border: 1px solid #ffffff;
  backdrop-filter: blur(10px);
  overflow: hidden;
}
::v-deep .el-input-group__prepend {
  width: 70px !important;
}
::v-deep .el-input-group__append {
  width: 70px !important;
  border-radius: 40px;
  color: #fff;
  background: linear-gradient(90deg, #3558ff 0%, #1f87cb 100%);
}
::v-deep .el-input-group--prepend .el-input__inner {
  height: 62px;
  // border-radius: 31px;
  background: rgba(255, 255, 255, 0.8);
  // border: 1px solid #ffffff;
  backdrop-filter: blur(10px);
  text-align: left;
}
::v-deep .el-input__inner {
  text-align: center;
}
.lcontent_body {
  position: relative;
}
.lcontent_input {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.lcontent_inputs {
  width: 80%;
  margin: 0 auto;
  color: #ffffff;
}
.top {
  top: 50.5%;
}
.image-mode {
  width: 100%;
  // object-fit: contain;
  object-fit: cover;
}
.pv-video-ls {
  width: 95%;
  overflow: hidden;
  margin: 0 auto;
}
.works {
  font-size: 17px;
  width: 100%;
  text-indent: 9px;
  height: 30px;
  line-height: 30px;
  margin: 10px auto;
  /* box-shadow: 0 0 0 0 #fff,0 0 0 0 #fff,0 0 0 0 #fff,0 0 8rpx 0 #f5f5f5; */
  box-shadow: 0px 2px 4px 0px #f5f5f5;
}
</style>
