<template>
  <div :class="[ 'lst-box']">
    <div class="">
      <div class="">
      
        <!-- <div class="m-wrapper-tit">{{成交记录}} <span v-if="modeData.isShowAll === 1">({{ modeData.isShowAll }})</span></div> -->
        <!-- <ul class="lst"> -->
          <!-- <li v-for="(item, index) in filterList" :key="index"> -->
            <div class="" v-if="modeData.isShowAll == 1||modeData.isShowAll == 3">
              <!-- <div class="text" >{{name }}</div> -->
              <!-- <div class="pic-body" :style="{ width:modeData.mapWidth+'%' }">
                <img :src="map" class="pic-map" alt="" :style="{ borderRadius:modeData.mapRadius+'px',height:modeData.mapHeight+'px' }">
              </div> -->
              <!-- <span class="name">{{ item.name }}</span> -->
            </div>
            <div class="u-pay" v-if="modeData.isShowAll == 1||modeData.isShowAll == 2">
            <bottomInfo :modeData='modeData' />
            </div>
          <!-- </li> -->
        <!-- </ul> -->
      </div>
      <!-- <div class="bg"></div> -->
    </div>
    <!-- <operate v-if="selected" @upComps="upComps" @downComps="downComps" @delComps="delComps" /> -->
  </div>
</template>

<script>
// import tempPhone from '@/mixins/tempPhone'
// import operate from './operate.vue'
// import { mapGetters } from "vuex";
import { getCompany } from "@/api/customer/outCustomer";
import bottomInfo from './bottom-info/bottom-info.vue'
// import map from '@/assets/imgs/map.png'
export default {
  name: 'Countdown',
  components: {
    // operate,
    bottomInfo
  },
  props: {
    modeData: {
      type: Object,
      default: () => {},
    },
  },
  // mixins: [tempPhone],
  data() {
    return {
      // map,
      name:"",
    }
  },

  created() {
    this.initGradeList();
    // this.loadData()
  },
  methods: {
    async initGradeList() {
      const res = await getCompany();
      this.name = res.result.name;
    }
  }
}
</script>

<style lang="scss" scoped>
.pic-body{
  width: 100%;
  margin: 0 auto;
  padding: 10px 0 ;
  // background: #56CED1;
}
.pic-map{
  width: 100%;
  margin: 0 auto;
  // border-radius: 15px;
}
.m-card{
  margin: 10px 0;
}
.text{

}
</style>
