var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['phone-inner-box', 'lst-box']},[_c('div',{staticClass:"m-card"},[_c('div',{staticClass:"m-wrapper lst-wrapper",style:({
        width: _vm.modeData.modelWidth + '%',
      })},[(_vm.modeData.modelName || _vm.modeData.modelSubName)?_c('div',{staticClass:"body-modelName"},[_c('div',{staticClass:"flex_center",style:({
            fontSize: _vm.modeData.modelSize + 'px',
            fontWeight: _vm.modeData.modelBold,
          })},[_c('div',{staticClass:"line_left"}),_vm._v(_vm._s(_vm.modeData.modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
            fontSize: _vm.modeData.modelsSize + 'px',
            fontWeight: _vm.modeData.modelsBold,
          })},[_vm._v(" "+_vm._s(_vm.modeData.modelSubName)+" ")])]):_vm._e(),_c('div',{staticClass:"m-wrapper-tit",style:({})},[_c('div',{staticClass:"content_introu",staticStyle:{"white-space":"pre-line","text-indent":"24px"},domProps:{"innerHTML":_vm._s(_vm.company.introduce.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp'))}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }