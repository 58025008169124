var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.modeData)?_c('div',{ref:"innerElement"},[(_vm.modeData.picOrVideo === 2 && _vm.modeData.value)?_c('div',{staticClass:"works"},[_vm._v(" 动态作品 ")]):_vm._e(),_c('div',{class:['phone-inner-box', 'lcontent-box']},[(_vm.modeData.modelName || _vm.modeData.modelSubName)?_c('div',{staticClass:"lcontent-box-modelName"},[_c('div',{staticClass:"flex_center",style:({
          fontSize: _vm.modeData.modelSize + 'px',
          lineHeight: _vm.modeData.modelLineHeight + 'px',
          fontWeight: _vm.modeData.modelBold,
        })},[_c('div',{staticClass:"line_left"}),_vm._v(" "+_vm._s(_vm.modeData.modelName)+" "),_c('div',{staticClass:"line_right"})]),_c('div',{style:({
          fontSize: _vm.modeData.modelsSize + 'px',
          fontWeight: _vm.modeData.modelsBold,
        })},[_vm._v(" "+_vm._s(_vm.modeData.modelSubName)+" ")])]):_vm._e(),(_vm.modeData.picList.length)?_c('div',{staticClass:"pv-ls-box"},[(_vm.modeData.picOrVideo === 1)?_c('div',{ref:"carousel",staticClass:"lcontent_body",style:({
          width: _vm.modeData.picWidth + '%',
          margin: '0 auto',
        })},[(_vm.modeData.picOrVideo === 1)?_c('el-carousel',{attrs:{"height":_vm.modeData.picHeight + 'px',"autoplay":"","autoplay-speed":3000}},_vm._l((_vm.modeData.picList.filter((r) => {
              return r.url.length != 0;
            })),function(item,index){return _c('el-carousel-item',{key:index},[(item.url.length)?_c('div',{class:_vm.isAnm ? 'thisbucuo move-img' : 'thisbucuo'},[_c('img',{staticClass:"image-mode",style:({
                  height: _vm.modeData.picHeight + 'px',
                }),attrs:{"src":item.url[0].url}})]):_vm._e()])}),1):_vm._e(),_c('div',{staticClass:"lcontent_input",style:({
            top: _vm.modeData.picHeight / 2 - 42 + 'px',
          })},[_c('div',{staticClass:"lcontent_inputs",style:({
              textAlign:
                _vm.modeData.contentPos == 2
                  ? 'left'
                  : _vm.modeData.contentPos == 3
                  ? 'right'
                  : 'center',
            })},[_c('div',{style:({
                fontSize: _vm.modeData.modelContentSize + 'px',
                fontWeight: _vm.modeData.modelContentBold,
              })},[_vm._v(" "+_vm._s(_vm.modeData.modelContent)+" ")]),_c('div',{style:({
                fontSize: _vm.modeData.modelSubContentSize + 'px',
                fontWeight: _vm.modeData.modelSubContentBold,
                margin: '30px 0 0 0',
              })},[_vm._v(" "+_vm._s(_vm.modeData.modelSubContent)+" ")])]),(_vm.modeData.searchType == 0)?_c('el-row',{staticStyle:{"margin-top":"20px"},attrs:{"align":"center","type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":10}},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"Search by target, catalog number, uniprot ID or keyword"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.query.searchValue),callback:function ($$v) {_vm.$set(_vm.query, "searchValue", $$v)},expression:"query.searchValue"}},[_c('el-select',{attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.query.category),callback:function ($$v) {_vm.$set(_vm.query, "category", $$v)},expression:"query.category"}},_vm._l((_vm.categoryOptions),function(item,index){return _c('el-option',{key:item.categoryId,attrs:{"label":item.name,"value":item.categoryId}})}),1),_c('el-button',{attrs:{"slot":"append","type":"primary"},on:{"click":_vm.handleSearch},slot:"append"},[_vm._v("搜索")])],1)],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.modeData.picOrVideo === 2)?_c('div',{staticClass:"pv-video-ls",style:({
          height: _vm.modeData.videoHeight + 'px',
          borderRadius: _vm.modeData.videoRadius + 'px',
        })},[(_vm.modeData.videoList.length)?[_vm._l((_vm.modeData.videoList),function(item,index){return _c('img',{key:index,attrs:{"src":item.poster,"width":"100%"}})}),_c('i',{staticClass:"el-icon-video-play"})]:[_c('img',{attrs:{"src":_vm.modeData.picList.length ? _vm.modeData.picList[0].url : _vm.defaultPoster,"width":"100%"}}),_c('i',{staticClass:"el-icon-video-play"})]],2):_vm._e()]):_c('div',{staticClass:"pv-ls-box"},[_c('img',{attrs:{"src":_vm.placeHolderImgUrl,"alt":"","width":"100%"}})])]),(_vm.modeData.picOrVideo === 2)?_c('div',{staticClass:"input_text",style:({ color: _vm.compsList[0].bgColor == '#ffffff' ? '#000000' : '#ffffff' })},[_vm._v(" "+_vm._s(_vm.modeData.input)+" ")]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }