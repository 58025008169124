<template>
  <div :class="['phone-inner-box', 'lst-box']">
    <div class="m-card">
      <div class="m-wrapper lst-wrapper" :style="{ width: modeData.modelWidth + '%' }">
        <div class="body-modelName" v-if="modeData.modelName || modeData.modelSubName">
          <div
          class="flex_center"
            :style="{
              fontSize: modeData.modelSize + 'px',
              fontWeight: modeData.modelBold,
            }"
          >
          <div class="line_left"> </div>{{ modeData.modelName }}
          <div class="line_right"> </div>
          </div>
          <div
            :style="{
              fontSize: modeData.modelsSize + 'px',
              fontWeight: modeData.modelsBold,
            }"
          >
            {{ modeData.modelSubName }}
          </div>
        </div>

        <div class="m-wrapper-tit" :style="{}">
          <div class="content_lian">
            <div class="flex_between">
              <div v-if="modeData.fileList.length">
                <img
                  :src="modeData.fileList[0].url"
                  class="pic"
                  object-fit
                  cover
                  alt=""
                />
              </div>
              <img v-else class="pic" object-fit cover alt="" />
              <div class="content_rights">
                <el-form :model="query" :rules="rules" ref="query" label-width="100px">
                  <!-- <el-form-item label="定制类型：" prop="category">
                    <el-row>
                      <el-col :span="24">
                        <el-select style="width:100%" v-model="query.category" placeholder="请选择">
                          <el-option
                            v-for="item in categoryOptions"
                            :key="item.categoryId"
                            :label="item.name"
                            :value="item.categoryId"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-form-item> -->
                  <el-form-item label="您的姓名：" prop="name">
                    <el-row>
                      <el-col :span="24">
                        <el-input
                          v-model="query.name"
                          placeholder="请输入您的姓名"
                          :maxlength="20"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="您的电话：" prop="phone">
                    <el-row>
                      <el-col :span="24">
                        <el-input
                          v-model="query.phone"
                          placeholder="请输入您的电话"
                          :maxlength="11"
                          type="number"
                          oninput="if(value.length>11)value=value.slice(0,11)"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="您的邮箱：" prop="email">
                    <el-row>
                      <el-col :span="24">
                        <el-input
                          v-model="query.email"
                          placeholder="请输入您的邮箱"
                          :maxlength="30"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="工作单位：" prop="">
                    <el-row>
                      <el-col :span="24">
                        <el-input
                          v-model="query.workUnit"
                          placeholder="请输入您的工作单位"
                          :maxlength="40"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="具体内容：" prop="">
                    <el-row>
                      <el-col :span="24">
                        <el-input
                          v-model="query.remark"
                          placeholder="请输入具体内容"
                          :maxlength="500"
                          :autosize="{ minRows: 5, maxRows: 5 }"
                          type="textarea"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="">
                    <div class="flex-end">
                      <el-button
                        style="padding: 10px 30px"
                        type="primary"
                        icon="el-icon-check"
                        round
                        @click="submit('query')"
                        >提交</el-button
                      >
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <!-- <div class="flex_between content_input">
              <div class="content_right">
                <el-form label-width="80px" label-position="left">
                  <el-form-item label="联系电话">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text">
                          {{ modeData.lianPhone }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="地址">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text overflow">
                          {{ modeData.lianAddress }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="产品订购">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text">
                          {{ modeData.lianProudt }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="客户服务">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text">
                          {{ modeData.lianCuroment }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="技术支持">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text">
                          {{ modeData.lianEmail }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="商务合作">
                    <el-row>
                      <el-col :span="24">
                        <div class="content_right_text">
                          {{ modeData.lianEmails }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="modeData.fileLists" class="pic_map">
                <MMap :saddress="modeData.fileLists.address" :slng="modeData.fileLists.lng" :slat="modeData.fileLists.lat"/>
              </div>
              <img v-else class="pic_map" object-fit cover alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="bg"></div> -->
    </div>
    <!-- <operate
      v-if="selected"
      @upComps="upComps"
      @downComps="downComps"
      @delComps="delComps"
    /> -->
  </div>
</template>

<script>
// import tempPhone from "@/mixins/tempPhone";
// import operate from "./operate.vue";
// import { mapGetters } from "vuex";
import MMap from "@/components/MMap/index.vue";
import { treeListCustomizeCategory } from "@/api/activity";
export default {
  name: "Spread",
  props: {
    modeData: {
      type: Object,
      default: () => {},
    },
  },
  components:{
    MMap,
  },
  data() {
    return {
      picList: [],
      options: [],
      categoryOptions: [],
      query: {
        category: "",
        email: "",
        name: "",
        phone: "",
        remark: "",
        title: "",
        workUnit: "",
        idempotentToken: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请选择您的手机号", trigger: "change" }],
        category: [
          {
            type: "date",
            required: true,
            message: "请选择您需要定制的类型",
            trigger: "change",
          },
        ],
        workUnit: [
          { type: "date", required: true, message: "请输入您的单位", trigger: "change" },
        ],
        email: [
          { type: "array", required: true, message: "请输入您的邮箱", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.initCategoryData();
    // this.$bus.$on("list", (value) => {
    //   this.picList = value;
    // });
  },

  methods: {
    // 提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(" submit!!");
          // this.$emit('submit',this.query)
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initCategoryData() {
      // 查询类目接口
      treeListCustomizeCategory()
        .then((res) => {
          console.log("查询类目", res);
          if (res.code === "200") {
            if (res.result.list && res.result.list.length > 0) {
              var arr = res.result.list;
              this.categoryOptions = arr;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取相册数据
    handleSearch() {
      listPageProduct({
        categoryId: this.modeData.employeeHas,
        name: "",
        pageNumber: 1,
        pageSize: this.modeData.showNum,
        type: 1,
      })
        .then((res) => {
          if (res.code === "200") {
            console.log(res);
            // this.$bus.$emit("list", res.result.list);
            // const info = _.cloneDeep(this.modeData.info);
            // list = res.result.list;
            if (res.result.list.length == 0) {
              this.$message({
                message: "暂无数据",
              });
            }
            this.changbeItem(info);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content_rights .el-form-item {
  margin: 0 0 24px 0;
}
.content_right .el-form-item {
  margin: 0 0 5px 0;
}
::v-deep .el-input--medium .el-input__inner {
  height: 62px;
  line-height: 62px;
}
::v-deep .el-button--medium .is-round {
  padding: 10px 30px;
  width: 200px;
  height: 58px;
  background: linear-gradient(90deg, #3558ff 0%, #1f87cb 100%);
  border-radius: 29px;
}
::v-deep .el-form-item__label {
  line-height: 62px;
}
::v-deep .el-input__inner {
  height: 62px;
}
.m-wrapper-tit {
  width: 100%;
  // display: flex;
  display: -webkit-box;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  .m-item {
    width: 50%;
    text-align: center;
    margin: 0 10px;
  }
}
::-webkit-scrollbar {
  display: none;
}
.name {
  text-align: center;
  font-size: 12px;
}

.btn {
  color: #82c8af;
  height: 38px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 80%;
  margin: 10px auto;
  font-size: 15px;
}
.pic {
  width: 580px;
  height: 580px;
}
.pic_map {
  width: 690px;
  height: 400px;
  overflow: hidden;
}
.content_right {
  width: 45%;
}
.content_rights {
  width: 45%;
}
.content_lian {
  width: 100%;
}
.content_input {
  margin-top: 100px;
}
.content_right_text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  height: 62px;
  line-height: 62px;
  color: #000000;
  text-align: left;
  font-style: normal;
}
</style>
