import { axios } from '@/utils/request'
// import excelRequest from '@/utils/excelRequest'
export function getAppletPageByType(data) {
    return axios({
        url: '/website/getAppletPageByType',
        method: 'post',
        data
    })
}
// 幂等
export function getToken(data) {
    return axios({
      url: '/idempotent/getToken',
      method: 'post',
      data
    })
  }
//分页查询文件
export function listPageFileManagement(data) {
    return axios({
        url: '/website/listPageFileManagement',
        method: 'post',
        data
    })
}
//根据商品id查看商品详情
export function getFileManagementById(data) {
    return axios({
        url: '/website/getFileManagementById',
        method: 'post',
        data
    })
}
// 分页查询页面管理
export function listPageModuleInstance(data) {
    return axios({
        url: '/activity/listPageModuleInstance',
        method: 'post',
        data
    })
}

//保存定制需求
export function saveCustomize(data) {
    return axios({
        url: '/website/saveCustomize',
        method: 'post',
        data
    })
}
//保存客户
export function saveCustomer(data) {
    return axios({
        url: '/website/saveCustomer',
        method: 'post',
        data
    })
}
// 分页获取页面列表
export function listPageActivity(data) {
    return axios({
        url: '/applet/listPageAppletPage',
        method: 'post',
        data
    })
}
// 定制类目
export function treeListCustomizeCategory(data) {
    return axios({
        url: '/website/treeListCustomizeCategory',
        method: 'post',
        data
    })
}
// 商品类目
export function listProductCategory(data) {
    return axios({
        url: '/website/listProductCategory',
        method: 'post',
        data
    })
}
// 商品标签
export function listProductLabel(data) {
    return axios({
        url: '/website/listProductLabel',
        method: 'post',
        data
    })
}

// // 新闻类目
// export function listPageArticle(data) {
//     return axios({
//         url: '/website/listPageArticle',
//         method: 'post',
//         data
//     })
// }
// 新闻类目
export function treeListArticleCategory(data) {
    return axios({
        url: '/website/treeListArticleCategory',
        method: 'post',
        data
    })
}
// 文件类目
export function treeListCustomerDiaryCategory(data) {
    return axios({
        url: '/website/treeListCustomerDiaryCategory',
        method: 'post',
        data
    })
}
// 分页查询产品
export function listPageProduct(data) {
    return axios({
        url: '/website/listPageProduct',
        method: 'post',
        data
    })
}
// 根据产品id查看产品详情
export function getProductById(data) {
    return axios({
        url: '/website/getProductById',
        method: 'post',
        data
    })
}
// 分页查询新闻知识
export function listPageArticle(data) {
    return axios({
        url: '/website/listPageArticle',
        method: 'post',
        data
    })
}
// 分页查询定制内容列表
export function listPageCustomizeContent(data) {
    return axios({
        url: '/website/listPageCustomizeContent',
        method: 'post',
        data
    })
}
// 定制内容详情
export function getCustomizeContentById(data) {
    return axios({
        url: '/website/getCustomizeContentById',
        method: 'post',
        data
    })
}
// 新闻详情
export function getArticleById(data) {
    return axios({
        url: '/website/getArticleById',
        method: 'post',
        data
    })
}

export function deleteActivityById(data) {
    return axios({
        url: '/applet/deleteAppletPageById',
        method: 'post',
        data
    })
}

export function saveActivityCopyById(data) {
    return axios({
        url: '/activity/saveActivityCopyById',
        method: 'post',
        data
    })
}

export function getActivityDataById(data) {
    return axios({
        url: '/activity/getActivityDataById',
        method: 'post',
        data
    })
}

export function listPageEmployeeShare(data) {
    return axios({
        url: '/activity/listPageEmployeeShare',
        method: 'post',
        data
    })
}
export function listPageDistributorShare(data) {
    return axios({
        url: '/activity/listPageDistributorShare',
        method: 'post',
        data
    })
}
export function listPageDistributorShareDetail(data) {
    return axios({
        url: '/activity/listPageDistributorShareDetail',
        method: 'post',
        data
    })
}

export function listPageCustomerShare(data) {
    return axios({
        url: '/activity/listPageCustomerShare',
        method: 'post',
        data
    })
}
export function listPageOrder(data) {
    return axios({
        url: '/activity/listPageOrder',
        method: 'post',
        data
    })
}

export function listPageNoVerification(data) {
    return axios({
        url: '/activity/listPageNoVerification',
        method: 'post',
        data
    })
}

export function listPageAlreadyVerification(data) {
    return axios({
        url: '/activity/listPageAlreadyVerification',
        method: 'post',
        data
    })
}

export function listPageBrowse(data) {
    return axios({
        url: '/activity/listPageBrowse',
        method: 'post',
        data
    })
}

export function listPageCustomerShareDetail(data) {
    return axios({
        url: '/activity/listPageCustomerShareDetail',
        method: 'post',
        data
    })
}

// export function listOrderExcel(params) {
//     return excelRequest({
//         url: '/activity/listOrderExcel',
//         method: 'get',
//         responseType: 'blob',
//         params
//     })
// }

// export function listPageNoVerificationExcel(params) {
//     return excelRequest({
//         url: '/activity/listPageNoVerificationExcel',
//         method: 'get',
//         responseType: 'blob',
//         params
//     })
// }

// export function listPageAlreadyVerificationExcel(params) {
//     return excelRequest({
//         url: '/activity/listPageAlreadyVerificationExcel',
//         method: 'get',
//         responseType: 'blob',
//         params
//     })
// }

export function getShareSecret(data) {
    return axios({
        url: '/shareSecret/getShareSecret',
        method: 'post',
        data
    })
}

export function getEmployeeH5OpenIdById(data) {
    return axios({
        url: '/employee/getEmployeeH5OpenIdById',
        method: 'post',
        data
    })
}
export function saveActivityExport(data) {
    return axios({
        url: '/activity/saveActivityExport',
        method: 'post',
        data
    })
}

export function listCustomerShareTree(data) {
    return axios({
        url: '/activity/listCustomerShareTree',
        method: 'post',
        data
    })
}

export function listPageEmployeeShareDetail(data) {
    return axios({
        url: '/activity/listPageEmployeeShareDetail',
        method: 'post',
        data
    })
}
export function listEmployeeShareTree(data) {
    return axios({
        url: '/activity/listEmployeeShareTree',
        method: 'post',
        data
    })
}
export function saveOrderRefund(data) {
    return axios({
        url: '/order/saveOrderRefund',
        method: 'post',
        data
    })
}
export function getActivityDetailData(data) {
    return axios({
        url: '/activity/getActivityDetailData',
        method: 'post',
        data
    })
}
export function listActivityDetailData(data) {
    return axios({
        url: '/activity/listActivityDetailData',
        method: 'post',
        data
    })
}
export function getAppletQrCode(data) {
    return axios({
        url: '/activity/getAppletQrCode',
        method: 'post',
        data
    })
}
export function listPageExpireVerification(data) {
    return axios({
        url: '/activity/listPageExpireVerification',
        method: 'post',
        data
    })
}

// export function listExpireVerificationExcel(params) {
//   return excelRequest({
//       url: '/activity/listExpireVerificationExcel',
//       method: 'get',
//       responseType: 'blob',
//       params
//   })
// }

export function updateExpireVerification(data) {
  return axios({
      url: '/activity/updateExpireVerification',
      method: 'post',
      data
  })
}

export function treeListActivityLabel(data) {
  return axios({
      url: '/activity/label/treeListActivityLabel',
      method: 'post',
      data
  })
}

export function getActivityLabelById(data) {
  return axios({
      url: '/activity/label/getActivityLabelById',
      method: 'post',
      data
  })
}

