<template>
  <div class="tab-bar">
    <!--  :style="{
      background: modeData.mapBgPic.length ? 'url(' + modeData.mapBgPic[0].url + ')' : '',
      'background-repeat': 'no-repeat',
      'background-size': '100%',
    }" -->
    <div class="tab-body">
      <div class="tab-bar-item">
        <div class="tab-bar-title">快速链接</div>

        <!-- <div v-for="(item, index) in categoryOptions" :key="index" class="tab-bar-texts">
          <a class="tab-bar-texts" @click="handleLink(item)">{{ item.name }}</a>
        </div> -->
        <div v-for="(item, index) in categoryOption" :key="index" class="tab-bar-texts">
          <a class="tab-bar-texts" href="https://www.dahuodong.vip/" target="_blank">{{
            item.name
          }}</a>
        </div>
        <!-- <div class="tab-bar-text">喀斯吗商城</div> -->
        <!-- <div class="tab-bar-text">瑞景采购平台</div> -->
        <!-- <div class=""> 官网：https://www.baidu.com/ </div> -->
      </div>
      <div class="tab-bar-item">
        <div class="tab-bar-title">
          联系我们{{ shopList.wxAccount }} <text class="text-co"> </text>
        </div>

        <div class="tab-bar-text" @click="func1">电话：{{ shopList.phone }}</div>
        <!-- <div class="tab-bar-text">商务合作：{{ shopList.emailCooperate }}</div>
        <div class="tab-bar-text">产品订购：{{ shopList.emailOrder }}</div>
        <div class="tab-bar-text">客户服务：{{ shopList.emailService }}</div>
        <div class="tab-bar-text">技术支持：{{ shopList.emailSupport }}</div> -->
        <div class="tab-bar-text" @click="func2">地址：{{ shopList.address }}</div>
        <!-- <div class=""> 官网：https://www.baidu.com/ </div> -->
      </div>

      <div class="tab-bar-items">
        <!-- <div>公众号二维码</div> -->
        <!-- <img
          class="tab-bar-img"
          v-if="shopList.listFullPic && shopList.listFullPic.length"
          :src="shopList.listFullPic[0]"
          alt=""
        /> -->
        <el-image
          class="tab-bar-img"
          v-if="shopList.listFullPic && shopList.listFullPic.length"
          :src="shopList.listFullPic[0]"
          :preview-src-list="shopList.listFullPic"
        >
        </el-image>
        <div>扫码关注</div>
      </div>
    </div>
    <!-- <div class="icon_list">
      <button class="icon_line">
        <i class="el-icon-phone color"></i>
      </button>
      <button class="icon_line">
        <i class="el-icon-chat-line-round color"></i>
      </button>
    </div> -->
  </div>
</template>

<script >
import { getCompany } from "@/api/customer/outCustomer";
import { treeListCustomizeCategory } from "@/api/activity";
export default {
  props: {
    selected: {
      // 当前选中的tab index
      type: Number,
      default: 0,
    },
    modeData: {
      type: Object,
      default: () => {},
    },
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      color: "#666666",
      selectedColor: "#F48182",
      categoryOptions: [],
      categoryOption: [
        {
          name: "聚客耗品",
          url: "https://www.dahuodong.vip/",
        },
      ],
      isRouter: true,
      shopList: {
        name: "", //商户名称
        fullLogoUrl: "", //商户图标
        phone: "", //手机号
        wxAccount: "", //微信号
        wxAccountUrl: "", //微信号图片地址
        fullWxAccountUrl: "", //去全路径微信号图片地址
      },
      srcList:[]
    };
  },
  mounted() {
    this.getShopInfo();
    this.initCategoryData();
  },
  methods: {
    func1() {
        console.log('click 跳转')

        wx.miniProgram.redirectTo({url: '/pages/index/index'})
      },
       func2() {
        try {
          if (wx.miniProgram) {
            wx.showToast({
                title: '成功',
                icon: 'success',
                duration: 2000
              })
        } else if (wx) {
          alert('微信内')
        } else {
          alert('普通弹窗')
        }
      } catch (error) {
        console.log('报错了')
        // alert('报错了')
      }
    },
    initCategoryData() {
      // 查询类目接口
      treeListCustomizeCategory()
        .then((res) => {
          console.log("查询类目", res);
          if (res.code === "200") {
            if (res.result.list && res.result.list.length > 0) {
              var arr = res.result.list;
              this.categoryOptions = arr.slice(0, 2);
              this.categoryOptions.push({
                linkId: 1,
                name: "产品中心",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleLink(item) {
      console.log("handleLink", item);

      if (item.linkId) {
        this.$router.replace({
          name: "product",
          query: {},
        });
      } else {
        console.log("item", item);
        console.log("this.isRouter", this.isRouter);
        console.log("this.isRouters", this.categoryId == item.categoryId);
        this.isRouter = !this.isRouter;
        if (this.categoryId == item.categoryId) {
          if (document.documentElement.scrollTop) {
            document.documentElement.scrollTop = 0;
          } else {
            document.body.scrollTop = 0;
          }
          return;
        }
        if (this.isRouter) {
          this.$router.push({
            name: "service",
            query: { categoryId: item.categoryId },
          });
        } else {
          this.$router.push({
            name: "services",
            query: { categoryId: item.categoryId },
          });
        }
      }
    },
    // 获取商家信息
    async getShopInfo() {
      const res = await getCompany();
      this.shopList = res.result;
      if (this.shopList.listFullPic.length) {
        localStorage.setItem("listFullPic", JSON.stringify(this.shopList));
      }
      console.log("商家信息", this.shopList);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  width: 100%;
  // padding: 0 15%;
  // height: 570px;
  // line-height: 48px;
  background: #151d33;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .tab-body {
    width: 71%;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .color {
    font-size: 24px;
    color: #848471;
  }
  .text-co {
    color: #70d0cf;
    font-size: 12px;
    margin-left: 7px;
    text-decoration: underline;
  }
  .tab-bar-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-bottom: 20px;
  }
  .tab-bar-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #a1a5ad;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .tab-bar-texts {
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #a1a5ad;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-decoration: none;
  }
  .tab-bar-texts:hover {
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #6fc8ff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .tab-bar-item {
    color: #848471;
    // height: 210px;
    line-height: 30px;
    // padding: 16px 25px;
    padding: 15px 0;
    padding-top: 28px;
  }
  .tab-bar-items {
    color: #848471;
    // height: 210px;
    line-height: 30px;
    // padding: 16px 25px;
    text-align: center;
    padding: 15px;
    padding-top: 28px;
  }
  .tab-bar-img {
    width: 120px;
    height: 120px;
  }
  .icon_list {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 20px;
    // margin-top: 20px;
    .icon_line {
      width: 40px;
      height: 40px;
      border: 1px solid #848471;
      margin: 0 10px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .foot_tabbr {
    width: 100%;
    height: 60px;
    // line-height: 60px;
    // background: #1c1f24;
    text-align: center;
    .title {
      font-size: 13px;
      color: #6fc8ff;
      height: 35px;
      line-height: 56px;
    }
    .verison {
      font-size: 11px;
    }
  }
}
.popup-title {
  padding: 20px;
}
.tab-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #f5f5f5;
  vertical-align: middle;
}
.popup-add {
  text-align: center;
  margin-bottom: 40px;
}
.popup-list {
  width: 100%;
  text-align: center;
  padding: 56px 0;
  .popup-center {
    width: 70%;
    background: #fff;
    text-align: center;
    border-radius: 20px;
    padding: 60px 0;
    margin: 0 auto;
  }
  .popup-iamge {
    width: 320px;
    height: 320px;
  }
  .center-text {
    margin-top: 40px;
  }
}
</style>
