import axios from 'axios'

import { Message } from 'element-ui'
// import { getToken } from '@/utils/auth' // get token from cookie

import _ from 'lodash'
import { VueAxios } from './axios'
import store from '@/store'
import router from '@/router'
import bus from '@/mixins/bus'

const service = axios.create({
  // baseURL: envs.api,
  baseURL: process.env.VUE_APP_BASE_API,
  // transformRequest: [
  //   function(data) {
  //     let ret = ''
  //     for (const it in data) {
  //       if (data[it]) {
  //         ret +=
  //           encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  //       }
  //     }
  //     return ret
  //   }
  // ],
  transformRequest: [data => {
    return JSON.stringify(data)
  }],
  // transformRequest: [function(data) {
  //   return qs.stringify(data)
  // }],
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'source':"pc"
  },
  timeout: 1000 * 60 * 60 // 请求超时时间
  // withCredentials: true
})

const err = error => {
  const {
    response: { data }
  } = error
  console.log(data)
  Message({
    message: data.msg || 'Error',
    type: 'error',
    duration: 2 * 1000
  })
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = "0f3ed7f3dc3a418fb0e8659f17ac043a"
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  config.data = config.data || {}
  for (const key in config.data) {
    if (Object.hasOwnProperty.call(config.data, key)) {
      const element = config.data[key]
      if (_.isString(element)) {
        config.data[key] = _.trim(element)
      }
    }
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.config && response.config.responseType === 'blob') {
      const responseConfig = response.config.data
      let fileNameExcel = ''
      const strs = responseConfig.split('&')
      if (strs) {
        const backFiled =JSON.parse(strs)
        fileNameExcel = backFiled.fileName
      }
      if (fileNameExcel !== '') {
        fileNameExcel = decodeURI(decodeURI(fileNameExcel))
      }
      const content = response
      if (content.data.type === 'application/json') {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = function() {
            try {
              const res = JSON.parse(reader.result)
              const { status, errorMsg } = res
              if (+status !== 1) {
                Message({
                  message: errorMsg,
                  type: 'error',
                  duration: 3 * 1000
                })
              }
              resolve(res)
            } catch (e) {
              console.log(e)
              reject(e)
            }
          }
          reader.readAsText(content.data)
        })
      } else {
        const blob = new Blob([content.data])
        let fileName = '导出文件.xlsx'
        if (fileNameExcel !== '') {
          fileName = fileNameExcel + '.xlsx'
        }
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
        return Promise.resolve({ status: 1 })
      }
    }
    else
    {
      const res = response.data
      if (+response.status === 200) {
        if (+res.code === 200) {
          return response.data
        } else {
          if (res.code === 401) {
            Message({
              message: res.message,
              type: 'error',
              duration: 2000,
              onClose: () => {
                store.dispatch('user/logout').then(() => {
                  // location.reload()
                  router.push(`/login`)
                }).catch((e) => {
                  console.log(e)
                })
              }
            })
          } else if (res.code === '201') {
            bus.$emit('changeDataSending', {dataSending: false})
          } else {
            Message({
              message: res.message,
              type: 'error',
              duration: 3 * 1000
            })
            bus.$emit('changeDataSending', {dataSending: false})
          }
          return Promise.reject(res)
        }
      } else {
        bus.$emit('changeDataSending', {dataSending: false})
        return response.data
        // if (+res.status === 1 && +res.code === 401) {
        //   Message({
        //     message: res.msg,
        //     type: 'error',
        //     duration: 3 * 1000
        //   })
        //   return Promise.reject('error')
        // } else {
        //   return response.data
        // }
      }
    }
  },
  error => {
    const {
      response: { data }
    } = error
    console.log(data, 'ererdf')
    let msg = '请求超时，请检查网路。'
    if (data.code === 400) {
      msg = data.message
    }
    Message({
      message: msg,
      type: 'error',
      duration: 3 * 1000
    })
    bus.$emit('changeDataSending', {dataSending: false})
    return Promise.reject(error)
  }
)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
