import { axios } from '@/utils/request'

export function listPageCustomer(data) {
  return axios({
    url: '/outCustomer/listPageCustomer',
    method: 'post',
    data
  })
}
// 获取商户信息
export function getCompany(data) {
  return axios({
    url: '/website/getCompany',
    method: 'post',
    data
  })
}
// 编辑商户信息
export function updateCompanyById(data) {
  return axios({
    url: '/company/updateCompanyById',
    method: 'post',
    data
  })
}

export function saveCustomer(data) {
  return axios({
    url: '/outCustomer/saveCustomer',
    method: 'post',
    data
  })
}

export function updateCustomer(data) {
  return axios({
    url: '/outCustomer/updateCustomer',
    method: 'post',
    data
  })
}

export function getCustomer(data) {
  return axios({
    url: '/outCustomer/getCustomer',
    method: 'post',
    data
  })
}

export function deleteCustomer(data) {
  return axios({
    url: '/outCustomer/deleteCustomer',
    method: 'post',
    data
  })
}

export function listPageMyBorrow(data) {
  return axios({
    url: '/outCustomer/listPageMyBorrow',
    method: 'post',
    data
  })
}

export function listPageMember(data) {
  return axios({
    url: '/outCustomer/listPageMember',
    method: 'post',
    data
  })
}
